import { SelectChangeEvent } from "@mui/material";
import { createContext } from "react";
import { AllowlistAddress, Asset, Wallet } from "../../../services/openAPI/client";

export interface WithdrawMinimal {
  assetTicker: string | null;
  assetSymbol: string | null;
  fromWalletId: number | null;
  fromWalletAddress: string | null;
  toWalletId: number | null;
  toWalletAddress: string | null;
  allowListId: number | null;
  allowListName: string | null;
  destinationType: string;
  quantity: string | number | null;
  originatorId: number;
  createdOn: number;
  accountId: number;
  organizationId: number;
  clientName: string;
  orgName: string;
  requesterName: string;
}

export interface WalletWithdrawContext {
  selectedAsset: string;
  currencyView: string;
  withdraw: Partial<WithdrawMinimal>;
  withdrawStep: number;
  selectedWallet: Wallet | null;
  selectedWalletId: number | null;
  selectedWalletType: string | undefined;
  isWarmBalance: boolean;
  addressList: AllowlistAddress[];
  allowListedAddresses: AllowlistAddress[] | [];
  policyAllowlistListPromise: Promise<AllowlistAddress[]> | undefined;
  gasBalance: number;
  feeWallet: Wallet | null;
  isToken: boolean;
  destinationAddressType: string;
  balances: any;
  selectedAddress: AllowlistAddress | null | undefined;
  selectedAddressId: number | null;
  setSelectedAddress: React.Dispatch<React.SetStateAction<AllowlistAddress | null | undefined>>;
  setSelectedAddressId: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectDestinationType: React.Dispatch<React.SetStateAction<string>>;
  setDestinationAddressType: React.Dispatch<React.SetStateAction<string>>;
  handleInputChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => void;
  handleCurrencyToggle: (event: React.MouseEvent<HTMLElement>, currency: string | null) => void;
  handleWalletType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeWallet: (event: SelectChangeEvent) => void;
  handleChangeAsset: (_: React.SyntheticEvent, newAsset: Asset | null) => void;
  updateField: (fieldName: string, value: string | number | boolean) => void;
  handleReviewWithdraw: () => void;
  handleCreateWithdraw: () => void;
  filterAddressesByAsset: (asset?: string, addressListCheck?: AllowlistAddress[]) => AllowlistAddress[] | [];
}

const defaultWalletWithdrawContextValue: WalletWithdrawContext = {
  selectedAsset: "",
  currencyView: "",
  withdraw: {},
  selectedWallet: null,
  selectedWalletId: null,
  selectedWalletType: "",
  withdrawStep: 1,
  isWarmBalance: false,
  policyAllowlistListPromise: undefined,
  addressList: [],
  allowListedAddresses: [],
  gasBalance: 0,
  feeWallet: null,
  isToken: false,
  destinationAddressType: "",
  balances: {},
  selectedAddress: null,
  selectedAddressId: null,
  setSelectedAddress: () => {
    undefined;
  },
  setSelectedAddressId: () => {
    undefined;
  },
  setSelectDestinationType: () => {
    undefined;
  },
  setDestinationAddressType: () => {
    undefined;
  },
  handleInputChange: () => {
    undefined;
  },
  handleCurrencyToggle: () => {
    undefined;
  },
  handleWalletType: () => {
    undefined;
  },
  handleChangeWallet: () => {
    undefined;
  },
  handleChangeAsset: () => {
    undefined;
  },
  updateField: () => {
    undefined;
  },
  handleReviewWithdraw: () => {
    undefined;
  },
  handleCreateWithdraw: () => {
    undefined;
  },
  filterAddressesByAsset: () => {
    return [];
  },
};

export const withdrawContext = createContext<WalletWithdrawContext>(defaultWalletWithdrawContextValue);
