import { ClickAwayListener, IconButton, SvgIcon, Tooltip, Unstable_Grid2 as Grid, Box, Stack } from "@mui/material";

import {
  AvailableAndPendingBalanceSummary,
  getAvailableAndPendingBalances,
  getDecimalLength,
  getPriceByAssetQty,
  getSVGStringForTicker,
  getScanLink,
} from "../../utils/dataUtils";
import { useLoaderData } from "react-router-dom";
import {
  Account,
  AllowlistAddress,
  AllowlistType,
  Wallet,
  WalletTransaction,
  WalletType,
} from "../../services/openAPI/client";
import React, { useState } from "react";
import { useRootContext } from "../../RootLayout";
import {
  Icons,
  Typography,
  formatDollarAmountUsd,
  formatCryptoQuantity,
  formatWalletAddress,
  Skeleton,
} from "@bakkt/bakkt-ui-components";
import { useTheme } from "@mui/material/styles";
import QuantityDisplay from "../../components/quantityDisplay/QuantityDisplay";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon";
import useGetNetworkFeeEstimation from "../../hooks/useGetNetworkFeeEstimation";
import { WithdrawMinimal } from "./withdraw/WithdrawContext";

interface withdrawReviewProps {
  withdrawRequest: Partial<WithdrawMinimal>;
  allowListedAddresses: AllowlistAddress[];
  setEnableWithdraw?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReviewWithdrawDetails(props: withdrawReviewProps) {
  const { withdrawRequest, allowListedAddresses, setEnableWithdraw } = {
    withdrawRequest: props.withdrawRequest,
    allowListedAddresses: props.allowListedAddresses,
    setEnableWithdraw: props.setEnableWithdraw,
  };

  const { orgDataCache, userInfo, selectedOrg, priceFeed, assets, networks } = useRootContext();
  const wallets: Wallet[] = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets];
  const account: Account = orgDataCache.account;
  const accountOrgs = orgDataCache.accountOrgs;
  const accountOmnibusWallets: Wallet[] = orgDataCache.accountOmnibusWallets;
  const { walletTransaction, walletTransactions } = useLoaderData() as {
    walletTransaction?: WalletTransaction;
    walletTransactions: WalletTransaction[];
  };

  const selectedWallet = wallets?.find((w) => w.walletId === withdrawRequest!.fromWalletId) as Wallet;
  const selectedDestinationAddress = allowListedAddresses?.find(
    (w) => w.id === withdrawRequest.allowListId,
  ) as AllowlistAddress;

  const [showSelectedAddressConfirm, setShowSelectedAddressConfirm] = useState(false);
  const [showWalletAddressConfirm, setShowWalletAddressConfirm] = useState(false);

  const { isLoading, isToken, networkFeeEstimation, networkFeeTicker, feeWallet } = useGetNetworkFeeEstimation(
    withdrawRequest,
    selectedWallet,
    selectedDestinationAddress,
    wallets,
    assets,
  );

  const theme = useTheme();

  const filteredTransactions = walletTransaction
    ? walletTransactions.filter((t) => t.walletTransactionId !== walletTransaction.walletTransactionId)
    : walletTransactions;
  const isPolicyCreated = withdrawRequest.createdOn ? true : false;
  const balances = getWalletBalances();

  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(selectedWallet, priceFeed, assets);
  }

  const pending =
    selectedWallet?.quantity && !isToken
      ? selectedWallet?.quantity - balances.availableBalanceCrypto - Number(networkFeeEstimation?.networkFee)
      : 0;
  const availableBalance = balances.availableBalanceCrypto + (isPolicyCreated ? Number(withdrawRequest?.quantity) : 0);

  const updatedBalance =
    availableBalance -
    Number(withdrawRequest?.quantity || 0) -
    (!isToken ? Number(networkFeeEstimation?.networkFee) : 0);

  if (setEnableWithdraw) {
    setEnableWithdraw(updatedBalance >= 0);
  }

  const copySelectedAddress = (address: string) => {
    setShowSelectedAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };

  const copyWalletAddress = (address: string) => {
    setShowWalletAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };

  const goToIconStyle = { width: 14, height: 14 };
  const copyIconStyle = { width: 15, height: 15, ml: 1, mr: 1.2 };

  const scanSelectedWalletAddress = getScanLink(
    selectedDestinationAddress?.assetSymbol || "",
    selectedDestinationAddress?.address || "",
    assets,
    networks,
  );

  const scanWalletAddress = getScanLink(
    selectedWallet?.assetSymbol || "",
    selectedWallet?.address || "",
    assets,
    networks,
  );

  const displayOmnibusAccountWalletName = (selectedWallet: Wallet) => {
    const accountName = accountOrgs.find((org: any) => org.id === selectedWallet?.organizationId)?.name;
    const name = `${accountName} ${selectedWallet.name}`;
    return name;
  };

  return (
    <>
      {isLoading && <Skeleton variant="rectangular" height={500} width={520} />}
      {!isLoading && (
        <Grid container spacing={1}>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Source</Typography>
            <Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">CLIENT</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{withdrawRequest.clientName || account?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">ORGANIZATION</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">{withdrawRequest.orgName || selectedOrg.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">REQUESTED BY</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">
                    {withdrawRequest.requesterName || userInfo.preferred_username}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">WALLET</Typography>
                </Grid>
                <Grid>
                  <Grid container justifyContent={"flex-end"}>
                    <Typography variant="subtitle1">
                      <SvgIcon
                        component={() =>
                          AssetSVGIcon({
                            svgString: getSVGStringForTicker(assets, withdrawRequest.assetTicker as string),
                            title: withdrawRequest.assetTicker as string,
                            sx: { width: 16, height: 16, mr: 1, mb: -0.3 },
                          })
                        }
                        inheritViewBox
                      />
                      {selectedWallet.type === WalletType.Custody
                        ? `${selectedWallet?.name} (${selectedWallet?.temperature})`
                        : displayOmnibusAccountWalletName(selectedWallet)}
                    </Typography>
                  </Grid>

                  {selectedWallet.type === WalletType.Custody && (
                    <>
                      <Grid direction="row" sx={{ textAlign: "right" }}>
                        {formatWalletAddress(selectedWallet.address as string)}
                        <ClickAwayListener onClickAway={() => setShowWalletAddressConfirm(false)}>
                          <Tooltip
                            onClose={() => setShowWalletAddressConfirm(false)}
                            open={showWalletAddressConfirm}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="top"
                            title="Address Copied"
                          >
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={() => copyWalletAddress(selectedWallet.address as string)}
                              disableRipple
                              disableFocusRipple
                            >
                              <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>

                        <IconButton sx={{ m: 0, p: 0 }} href={scanWalletAddress} target="_blank" disableRipple={true}>
                          <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={goToIconStyle} />
                        </IconButton>
                      </Grid>
                      <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                        #{selectedWallet.walletId}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Destination</Typography>
            <Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">
                    {selectedDestinationAddress?.type === AllowlistType.Internal
                      ? AllowlistType.Internal
                      : AllowlistType.External}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle1">
                    {selectedDestinationAddress.tradingAddress ? selectedOrg.name : selectedDestinationAddress.name}
                  </Typography>
                  <Grid direction="row" sx={{ textAlign: "right" }}>
                    {formatWalletAddress(
                      selectedDestinationAddress.tradingAddress
                        ? (selectedDestinationAddress.tradingAddress as string)
                        : (selectedDestinationAddress.address as string),
                    )}

                    {!selectedDestinationAddress.tradingAddress && (
                      <>
                        <ClickAwayListener onClickAway={() => setShowSelectedAddressConfirm(false)}>
                          <Tooltip
                            onClose={() => setShowSelectedAddressConfirm(false)}
                            open={showSelectedAddressConfirm}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="top"
                            title="Address Copied"
                          >
                            <IconButton
                              sx={{ m: 0, p: 0 }}
                              onClick={() => copySelectedAddress(selectedDestinationAddress.address as string)}
                              disableRipple={true}
                            >
                              <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                        <IconButton
                          sx={{ m: 0, p: 0 }}
                          href={scanSelectedWalletAddress}
                          target="_blank"
                          disableRipple={true}
                        >
                          <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={goToIconStyle} />
                        </IconButton>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ fontWeight: 600 }}>Amounts</Typography>
            <Grid sx={{ borderBottom: 1, borderColor: theme.palette.divider, pb: 1 }}>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">AVAILABLE BALANCE</Typography>
                </Grid>
                <Grid direction="row" sx={{ textAlign: "right" }}>
                  <Typography variant="subtitle1">
                    <QuantityDisplay quantity={availableBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                    {(selectedWallet && selectedWallet.assetTicker) || ""}
                  </Typography>
                  <Typography variant="subtitle1">
                    {formatDollarAmountUsd(
                      getPriceByAssetQty(
                        withdrawRequest.assetTicker as string,
                        Number(availableBalance),
                        priceFeed,
                        assets,
                      ) || 0,
                    )}{" "}
                    USD
                  </Typography>
                  <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                    ( {pending} ) {(selectedWallet && selectedWallet.assetTicker) || ""} Pending
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">WITHDRAW AMOUNT</Typography>
                </Grid>
                <Grid direction="row" sx={{ textAlign: "right" }}>
                  <Typography variant="subtitle1">
                    {formatCryptoQuantity(
                      Number(withdrawRequest.quantity) || 0,
                      withdrawRequest.assetTicker || "",
                      "long",
                      getDecimalLength(Number(withdrawRequest.quantity)),
                    )}{" "}
                    {(selectedWallet && selectedWallet.assetTicker) || ""}
                  </Typography>
                  <Typography variant="subtitle1">
                    {formatDollarAmountUsd(
                      getPriceByAssetQty(
                        withdrawRequest.assetTicker as string,
                        Number(withdrawRequest.quantity),
                        priceFeed,
                        assets,
                      ) || 0,
                    )}{" "}
                    USD
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">NETWORK FEE ESTIMATION</Typography>
                </Grid>
                <Grid direction="row" sx={{ textAlign: "right" }}>
                  <Typography variant="subtitle1">
                    {formatCryptoQuantity(
                      Number(networkFeeEstimation?.networkFee) || 0,
                      selectedWallet.assetTicker || "",
                      "long",
                    )}{" "}
                    {isToken ? networkFeeTicker : (selectedWallet && selectedWallet.assetTicker) || ""}
                  </Typography>
                  <Typography variant="subtitle1">
                    {formatDollarAmountUsd(
                      getPriceByAssetQty(
                        networkFeeTicker || "",
                        Number(networkFeeEstimation?.networkFee),
                        priceFeed,
                        assets,
                      ) || 0,
                    )}{" "}
                    USD
                  </Typography>
                  {isToken && feeWallet && (
                    <Stack direction={"row"} sx={{ p: 0, m: 0 }} justifyContent={"flex-end"} alignContent={"center"}>
                      <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                        *Fee amount will be deducted in {feeWallet.assetTicker}
                      </Typography>
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Fee amount will be deducted in {feeWallet.assetTicker} from {feeWallet.name} for{" "}
                            {feeWallet.network} transactions
                          </Typography>
                        }
                        placement="top-end"
                      >
                        <Box>
                          <SvgIcon component={Icons.InformationIcon} sx={{ width: 20, height: 20 }} />
                        </Box>
                      </Tooltip>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
                <Grid>
                  <Typography variant="subtitle2">UPDATED BALANCE</Typography>
                </Grid>
                <Grid direction="row" sx={{ textAlign: "right" }}>
                  <Typography variant="subtitle1">
                    <QuantityDisplay quantity={updatedBalance || 0} ticker={selectedWallet.assetTicker || ""} />{" "}
                    {(selectedWallet && selectedWallet.assetTicker) || ""}
                  </Typography>
                  <Typography variant="subtitle1">
                    {formatDollarAmountUsd(
                      getPriceByAssetQty(
                        withdrawRequest.assetTicker as string,
                        Number(updatedBalance),
                        priceFeed,
                        assets,
                      ) || 0,
                    )}{" "}
                    USD
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
