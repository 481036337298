import { formatCryptoQuantity, formatCurrency, formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { getAvailableAndPendingBalances, getPriceByAssetQty } from "../../../utils/dataUtils";

import { useRootContext } from "../../../RootLayout";
import { Typography, color } from "@bakkt/bakkt-ui-components";
import { WalletType } from "../../../services/openAPI/client";

interface walletAvailableProps {
  walletId: string;
  walletName?: string;
}

export const WalletBalanceAvailableOnly = ({ walletId, walletName }: walletAvailableProps) => {
  const { orgDataCache, priceFeed, assets } = useRootContext();
  const wallets = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets, ...orgDataCache.offExchangeWallets];
  const wallet = wallets.find((wallet) => wallet.walletId === Number(walletId));
  const balances = getAvailableAndPendingBalances(wallet, priceFeed, assets);

  const bodyHeadingSx = {
    color: color.text.secondary,
    fontWeight: "bold",
  };

  const isOffExchangeWallet = wallet?.type === WalletType.OffExchange ? true : false;
  const cryptoBalance = isOffExchangeWallet ? wallet?.availableBalance || 0 : balances.availableBalanceCrypto || 0;
  return (
    <>
      <Typography variant="body2" sx={bodyHeadingSx}>
        {walletName ? `${walletName.toUpperCase()} AMOUNT AVAILABLE` : "AMOUNT AVAILABLE"}
      </Typography>
      <Typography variant="h4">
        {formatCryptoQuantity(cryptoBalance, "long")} {(wallet && wallet.assetTicker) || ""}
      </Typography>
      <Typography variant="subtitle1">
        {isOffExchangeWallet
          ? formatCurrency(
              getPriceByAssetQty(wallet?.assetTicker || "", wallet?.availableBalance || 0.0, priceFeed, assets),
            )
          : formatDollarAmountUsd(balances.availableBalanceUsd || 0)}{" "}
        USD
      </Typography>
    </>
  );
};
