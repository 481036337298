import { Await, useFetcher, useNavigate, useParams, useRouteError } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Alert, Button, color, Dialog, DialogTitle, Icons, TextField } from "@bakkt/bakkt-ui-components";
import {
  checkWarmBalance,
  getMinimunFeeAmount,
  getRemainingColdWallet,
  getRemainingWarmWallet,
  isTokenAsset,
} from "../../utils/dataUtils";
import { WalletService } from "../../services/serviceLoader";
import { Asset, CreateWalletRequest, PriceInfo, Status, Wallet, WalletType } from "../../services/openAPI/client";
import { WalletTemp } from "../../utils/CryptoIconsMap";
import LoadingIndicator from "../../components/loading/LoadingIndicator.tsx";
import { AxiosError } from "axios";
import { RootContextType, useRootContext } from "../../RootLayout.tsx";
import { useTheme } from "@mui/material/styles";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";
import { createOmnibusWallet } from "./ActivateOmnibusWallet.tsx";
import AssetDropDown from "../../components/assetDropDown/AssetDropDown.tsx";

export interface WalletFormData {
  accountId: number;
  organizationId: number;
  assetTicker: string;
  assetSymbol: string;
  network: string;
  name: string;
  description: string;
  temperature: string;
  type?: string;
  feeWalletId?: number | null;
}

const blankWalletFormData: WalletFormData = {
  accountId: 0,
  organizationId: 0,
  assetTicker: "",
  network: "",
  name: "",
  description: "",
  temperature: "",
  type: "",
  assetSymbol: "",
  feeWalletId: null,
};

const mapFormData = (wallet: WalletFormData): CreateWalletRequest => {
  return {
    accountId: Number(wallet.accountId),
    organizationId: Number(wallet.organizationId),
    assetSymbol: wallet.assetSymbol,
    name: wallet.name,
    description: wallet.description,
    type: wallet.type || WalletType.Custody,
    feeWalletId: wallet.feeWalletId || undefined,
  };
};
export default function AddWallet() {
  const theme = useTheme();
  const { orgDataCache, userInfo, selectedOrg, priceFeed, setShouldRefreshOrgOmnibusWallets, assets } =
    useRootContext() as RootContextType;
  const wallets = orgDataCache.wallets;

  const { organizationId } = useParams();
  const { addAlert } = useRootContext();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const error = useRouteError() as AxiosError | any;
  const isLoading = fetcher.state === "submitting";

  const [maxWalletLimit, setMaxWalletLimit] = useState<number>(
    selectedOrg.maxWalletsPerAsset ? selectedOrg.maxWalletsPerAsset : 5,
  );
  const [numberOfColdRemaining, setNumberOfColdRemaining] = useState(maxWalletLimit);
  const [numberOfWarmRemaining, setNumberOfWarmRemaining] = useState(maxWalletLimit);
  const [newWalletType, setNewWalletType] = useState("");
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  //For Token Assets calculate GAS balance, for now default Asset Warm wallet will serve as Fee Wallet.
  const [isToken, setIsTokenAsset] = useState<boolean>(false);
  const [feeWallet, setFeeWallet] = useState<Wallet | null>(null);

  const [wallet, setWallet] = useState<WalletFormData>(blankWalletFormData);
  useEffect(() => {
    updateField("organizationId", organizationId ? organizationId.toString() : "");
    updateField("accountId", userInfo?.accountId.toString());
    setWallet((prevData) => ({
      ...prevData,
      temperature: WalletTemp.Cold,
    }));
  }, []);

  const isWarmBalance = checkWarmBalance(wallets, priceFeed as PriceInfo[], assets);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response?.success) {
        addAlert({
          severity: "success",
          messageHeader: "Successfully created a wallet",
        });
        if (wallet.type === WalletType.Trading) {
          setShouldRefreshOrgOmnibusWallets(true);
        }
        navigate(-1);
      } else {
        addAlert({
          severity: "error",
          messageHeader: "Error creating wallet",
          message: `${response.message}: ${response.data.message}`,
        });
        navigate(-1);
      }
    }
  }, [fetcher.data]);

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const getAvailableTokenFeeWallets = (wallet: WalletFormData) => {
    const tokenAsset = assets.find((asset) => asset.symbol === wallet.assetSymbol);
    const tokenWallets = wallets.filter((w) => w.assetSymbol === tokenAsset?.symbol);
    const feeWallets = wallets
      .filter((w) => w.assetSymbol === tokenAsset?.feeAsset)
      .filter((w) => !tokenWallets.some((tw) => tw.feeWalletId === w.walletId));

    return { feeWallets, tokenAsset };
  };

  const isValidFeeWallet = () => {
    //it should return true for base asset
    if (!isToken) return true;

    // it should return false if there is no feeWalletId
    if (!wallet.feeWalletId) return false;

    const tokenAsset = assets.find((asset) => asset.symbol === wallet.assetSymbol);
    const tokenFeeWallet = getAvailableTokenFeeWallets(wallet).feeWallets.find(
      (w) => w.walletId === wallet.feeWalletId,
    );
    const availableBalance = tokenFeeWallet?.availableBalance || 0.0;
    const minimumFeeAmt = tokenAsset?.minimumFeeAmount || 0.0;

    return availableBalance >= minimumFeeAmt;
  };

  const isFormValid = () => {
    if (
      (isWarmBalance &&
        wallet.assetSymbol !== "" &&
        wallet.temperature !== "" &&
        wallet.name !== "" &&
        isValidFeeWallet() &&
        ((numberOfColdRemaining > 0 && wallet.temperature === WalletTemp.Cold) ||
          (numberOfWarmRemaining > 0 && wallet.temperature === WalletTemp.Warm))) ||
      (!isWarmBalance &&
        wallet.assetSymbol !== "" &&
        wallet.name !== "" &&
        numberOfColdRemaining > 0 &&
        isValidFeeWallet()) ||
      (newWalletType === WalletType.Trading && wallet.assetSymbol !== "" && isValidOmnibusAsset(wallet.assetSymbol))
    ) {
      return true;
    }
    return false;
  };

  const handleAssetChange = (_: React.SyntheticEvent<Element, Event>, newWalletAsset: Asset | null) => {
    if (newWalletAsset) {
      updateField("assetSymbol", newWalletAsset.symbol);
      updateField("assetTicker", getTickerFromSymbol(newWalletAsset.symbol));
      updateWalletLimitNetwork(newWalletAsset.symbol);
      setFeeWallet(null);
      updateField("feeWalletId", null);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
    const { name, value } = event.target;
    updateField(name, value);

    if (name === "type") {
      setNewWalletType(value);
    }

    if (name === "feeWalletId") {
      updateField(name, Number(value));
      const feeWallet = wallets.find((w) => w.walletId === Number(value));
      setFeeWallet(feeWallet ?? null);
    }
  };

  const updateRemainingWallets = (assetSymbol: string, maxLimit: number) => {
    setNumberOfColdRemaining(getRemainingColdWallet(assetSymbol, wallets, wallet.type || WalletType.Custody, maxLimit));
    setNumberOfWarmRemaining(getRemainingWarmWallet(assetSymbol, wallets, wallet.type || WalletType.Custody, maxLimit));
  };

  function getTickerFromSymbol(symbol: string) {
    const asset = assets.find((asset) => asset.symbol === symbol);
    return asset?.ticker || "";
  }

  const updateWalletLimitNetwork = (value: string) => {
    const isToken = isTokenAsset(value, assets);
    const limit: number = selectedOrg.maxWalletsPerAsset ? selectedOrg.maxWalletsPerAsset : 5;
    setMaxWalletLimit(limit);
    updateRemainingWallets(value, limit);

    setIsTokenAsset(isToken);
  };

  const updateField = (fieldName: string, value: string | number | null) => {
    setWallet((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleCreateWallet = () => {
    if (wallet.type === WalletType.Trading) {
      const omnibusWallet = createOmnibusWallet(wallet.assetSymbol, selectedOrg);
      fetcher.submit(JSON.stringify(omnibusWallet), {
        method: "post",
        encType: "application/json",
      });
    } else {
      fetcher.submit(JSON.stringify(wallet), {
        method: "post",
        encType: "application/json",
      });
    }
  };

  const isColdWalletWarning = () => {
    return numberOfColdRemaining <= 0 && wallet.assetSymbol !== "";
  };
  const isWarmWalletWarning = () => {
    return numberOfWarmRemaining <= 0 && wallet.assetSymbol !== "";
  };

  let walletTemperatureDisplay = "";
  const walletDisplayWarning = () => {
    const isColdLimitReached = numberOfColdRemaining <= 0 && wallet.assetSymbol !== "";

    if (!isWarmBalance) {
      walletTemperatureDisplay = "cold";
      return isColdLimitReached;
    }

    if (isColdLimitReached) {
      walletTemperatureDisplay = "cold";
      return isColdLimitReached;
    } else {
      return false;
    }
  };

  const isValidOmnibusAsset = (assetSymbol: string) => {
    const existingWallet = orgDataCache.omnibusWallets.filter((wallet) => wallet.assetSymbol === assetSymbol).length;
    if (existingWallet === 0) {
      return true;
    } else {
      return false;
    }
  };
  const disableOmnibus = orgDataCache.omnibusWallets.length === 0;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={false}>
      <DialogTitle title={"Create a Wallet"}>
        {`Please select the fields below to add a wallet. There is a limit of ${maxWalletLimit} segregated wallets per asset, and 1 omnibus wallet per asset.
           For additional wallets, please contact support.`}
      </DialogTitle>

      <DialogContent sx={{ pt: "0 !important" }}>
        <React.Suspense
          fallback={
            <Stack sx={{ mt: 7 }}>
              <LoadingIndicator />
            </Stack>
          }
        >
          <Await resolve={wallets} errorElement={<Alert severity="error">Error loading wallet data!</Alert>}>
            {isLoading ? (
              <Box sx={{ minHeight: 250 }}>
                <LoadingIndicator description={"Wallet is being created. Please wait..."} />
              </Box>
            ) : (
              <>
                <Grid container sx={{ my: 3 }}>
                  <Grid xs={12}>
                    <Stack spacing={2}>
                      {/*
                      The feature flag determines what type of wallet you can create
                      Custody or Trading. When the feature flag is off only Custody wallets
                      can be created. You can't select what kind you want. Checking that the
                      newWalletType is not equal to Trading keeps the modal working as
                      intended when the feature flag is off or on.

                      Reference: lines 355-395 determine if newWalletType can be set
                      based on the feature flag value.
                      */}
                      {isToken && feeWallet && newWalletType !== WalletType.Trading && (
                        <Alert severity="info">
                          This asset is on {feeWallet.network}. {feeWallet.network} assets require a minimum balance of{" "}
                          {getMinimunFeeAmount(feeWallet.assetTicker, assets)} {feeWallet.assetTicker} from{" "}
                          {feeWallet.name} for GAS fees. {feeWallet.assetTicker} will be deducted from{" "}
                          {feeWallet.assetTicker} wallet {feeWallet.name} for GAS fees.
                        </Alert>
                      )}
                      {walletDisplayWarning() && newWalletType !== WalletType.Trading && (
                        <Alert severity="warning">
                          You are unable to create additional {walletTemperatureDisplay} wallets. Contact support to add
                          more.
                        </Alert>
                      )}
                      {isWarmBalance &&
                        wallet.assetSymbol !== "" &&
                        wallet.temperature === WalletTemp.Cold &&
                        numberOfColdRemaining > 0 &&
                        newWalletType !== WalletType.Trading && (
                          <Alert severity="info">
                            You are able to create {numberOfColdRemaining} additional cold wallets.
                          </Alert>
                        )}
                      {!isWarmBalance &&
                        wallet.assetSymbol !== "" &&
                        numberOfColdRemaining > 0 &&
                        newWalletType !== WalletType.Trading && (
                          <Alert severity="info">
                            You are able to create {numberOfColdRemaining} additional {wallet.assetTicker} wallets.
                          </Alert>
                        )}
                      {newWalletType === WalletType.Trading && (
                        <Alert severity="info">You are only able to create 1 omnibus wallet per asset.</Alert>
                      )}
                      {error && (
                        <Alert severity="error">
                          {error.message
                            ? error.message
                            : "Failed to create wallet. Please try again later or contact support."}
                        </Alert>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container spacing={2} data-testid="addWalletContainerGrid">
                  {isREL009PublicFeature && (
                    <Grid xs={12}>
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <FormLabel sx={{ color: theme.palette.primary.main }}>Type</FormLabel>
                        <RadioGroup row onChange={handleInputChange} name="type">
                          <FormControlLabel value={WalletType.Custody} control={<Radio />} label="Segregated" />
                          <Stack
                            direction={"row"}
                            alignContent={"flex-end"}
                            alignItems={"flex-end"}
                            sx={{ p: 0, m: 0 }}
                          >
                            <FormControlLabel
                              value={WalletType.Trading}
                              control={<Radio />}
                              label="Omnibus"
                              sx={{ mr: 0 }}
                              disabled={disableOmnibus}
                            />
                            {disableOmnibus && (
                              <Tooltip
                                title={
                                  <Typography variant="body2">
                                    Please activate omnibus in the dashboard in order to create omnibus wallets.
                                  </Typography>
                                }
                                placement="top"
                              >
                                <Box>
                                  <SvgIcon
                                    component={Icons.InformationIcon}
                                    sx={{ width: 22, height: 22, color: color.text.disabled }}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid xs={12}>
                    <FormControl variant="standard" sx={{ width: "100%" }} required>
                      <AssetDropDown
                        handleAssetChange={handleAssetChange}
                        assets={assets.filter((asset) => asset.status === Status.Active)}
                        wallet={wallet}
                      />
                    </FormControl>
                  </Grid>
                  {newWalletType !== WalletType.Trading && (
                    <>
                      {isToken && (
                        <Grid xs={12}>
                          <FormControl variant="standard" sx={{ width: "100%" }} required>
                            <InputLabel id="select-fee-wallet">Select Fee Wallet</InputLabel>
                            <Select
                              value={String(wallet.feeWalletId) ?? ""}
                              label="feeWalletId"
                              onChange={handleInputChange}
                              name="feeWalletId"
                            >
                              {getAvailableTokenFeeWallets(wallet).feeWallets.length > 0 ? (
                                getAvailableTokenFeeWallets(wallet).feeWallets.map((wallet, index) => (
                                  <MenuItem key={index} value={wallet.walletId}>
                                    <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
                                      <Typography sx={{ fontWeight: 600, mr: 1 }}>{wallet.name}</Typography>
                                      <Typography variant={"body2"}>ID: {wallet.walletId}</Typography>
                                    </Grid>
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>
                                  <Grid container>
                                    <Typography sx={{ fontWeight: 600 }}>
                                      A new {getAvailableTokenFeeWallets(wallet).tokenAsset?.feeAsset} wallet is
                                      required to create a new {getAvailableTokenFeeWallets(wallet).tokenAsset?.ticker}{" "}
                                      wallet
                                    </Typography>
                                  </Grid>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid xs={12}>
                        <TextField
                          name="name"
                          value={wallet.name}
                          onChange={handleInputChange}
                          label="Wallet Name"
                          variant="standard"
                          disabled={isColdWalletWarning()}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          name="description"
                          value={wallet.description}
                          onChange={handleInputChange}
                          label="Wallet Description (Optional)"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </Await>
        </React.Suspense>
      </DialogContent>

      <DialogActions>
        <Button variant={"outlined"} onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant={"contained"} onClick={handleCreateWallet} autoFocus disabled={!isFormValid()}>
          Create Wallet
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export async function action({ request }: { request: Request }) {
  try {
    const walletFormData = (await request.json()) as WalletFormData;
    const walletFormRequest = mapFormData(walletFormData);
    const createWalletResponse = await WalletService.createWallet(walletFormRequest);
    return formatActionSuccessResponse(createWalletResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
