import { ClickAwayListener, IconButton, SvgIcon, Tooltip, Unstable_Grid2 as Grid } from "@mui/material";
import {
  AvailableAndPendingBalanceSummary,
  getAvailableAndPendingBalances,
  getDecimalLength,
  getPriceByAssetQty,
  getSVGStringForTicker,
} from "../../utils/dataUtils";
import { Account, Wallet } from "../../services/openAPI/client";
import { useState } from "react";
import { useRootContext } from "../../RootLayout";
import {
  Icons,
  Typography,
  formatDollarAmountUsd,
  formatCryptoQuantity,
  formatWalletAddress,
} from "@bakkt/bakkt-ui-components";
import { useTheme } from "@mui/material/styles";
import QuantityDisplay from "../../components/quantityDisplay/QuantityDisplay";
import { TransferFormData } from "./WalletTransfer";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

interface transferReviewProps {
  transferRequest: TransferFormData;
}

export default function ReviewTransferDetails(props: transferReviewProps) {
  const { transferRequest } = {
    transferRequest: props.transferRequest,
  };
  const { orgDataCache, userInfo, selectedOrg, priceFeed, assets } = useRootContext();
  const account: Account = orgDataCache.account;
  const accountOmnibusWallets = orgDataCache.accountOmnibusWallets;
  const orgOmnibusWallets = orgDataCache.omnibusWallets;

  const [selectedSourceWallet] = useState<Wallet>(
    orgOmnibusWallets!.find((w) => w.walletId === transferRequest!.fromWalletId) as Wallet,
  );

  const [selectedDestinationWallet] = useState<Wallet>(
    accountOmnibusWallets?.find((w) => w.walletId === transferRequest.destinationWalletId) as Wallet,
  );

  const [showDestinationAddressConfirm, setShowDestinationAddressConfirm] = useState(false);
  const [showSourceAddressConfirm, setShowSourceAddressConfirm] = useState(false);

  const theme = useTheme();

  const balances = getWalletBalances();

  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(selectedSourceWallet, priceFeed, assets);
  }

  const pending = selectedSourceWallet?.quantity ? selectedSourceWallet?.quantity - balances.availableBalanceCrypto : 0;
  const newAssetBalance = transferRequest?.quantity
    ? balances.availableBalanceCrypto - Number(transferRequest?.quantity)
    : balances.availableBalanceCrypto - Number(transferRequest?.quantity);

  const copySelectedDestinationAddress = (address: string) => {
    setShowDestinationAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };

  const copySelectedSourceAddress = (address: string) => {
    setShowSourceAddressConfirm(true);
    navigator.clipboard.writeText(address || "");
  };

  const goToIconStyle = { width: 14, height: 14 };
  const copyIconStyle = { width: 15, height: 15, ml: 1, mr: 1.2 };

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Source</Typography>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">CLIENT</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">{transferRequest.clientName || account?.name}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">ORGANIZATION</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">{transferRequest.orgName || selectedOrg.name}</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">REQUESTED BY</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">
                {transferRequest.requesterName || userInfo.preferred_username}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">WALLET</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">
                <SvgIcon
                  component={() =>
                    AssetSVGIcon({
                      svgString: getSVGStringForTicker(assets, transferRequest.assetTicker as string),
                      title: transferRequest.assetTicker as string,
                      sx: { width: 32, height: 32, pt: 2 },
                    })
                  }
                />
                {selectedSourceWallet?.name}
              </Typography>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                {formatWalletAddress(selectedSourceWallet.address as string)}
                <ClickAwayListener onClickAway={() => setShowSourceAddressConfirm(false)}>
                  <Tooltip
                    onClose={() => setShowSourceAddressConfirm(false)}
                    open={showSourceAddressConfirm}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Address Copied"
                  >
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      onClick={() => copySelectedSourceAddress(selectedSourceWallet.address as string)}
                      disableRipple
                      disableFocusRipple
                    >
                      <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </Grid>
              <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                #{selectedSourceWallet.walletId}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Destination</Typography>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">INTERNAL</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">{selectedDestinationWallet.name}</Typography>
              <Grid direction="row" sx={{ textAlign: "right" }}>
                {formatWalletAddress(selectedDestinationWallet.address as string)}
                <ClickAwayListener onClickAway={() => setShowDestinationAddressConfirm(false)}>
                  <Tooltip
                    onClose={() => setShowDestinationAddressConfirm(false)}
                    open={showDestinationAddressConfirm}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Address Copied"
                  >
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      onClick={() => copySelectedDestinationAddress(selectedDestinationWallet.address as string)}
                      disableRipple={true}
                    >
                      <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={copyIconStyle} />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Typography sx={{ fontWeight: 600 }}>Amounts</Typography>
        <Grid sx={{ borderBottom: 1, borderColor: theme.palette.divider, pb: 1 }}>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">AVAILABLE BALANCE</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                <QuantityDisplay
                  quantity={balances.availableBalanceCrypto || 0}
                  ticker={selectedSourceWallet.assetTicker || ""}
                />{" "}
                {(selectedSourceWallet && selectedSourceWallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(balances.availableBalanceUsd || 0)} USD
              </Typography>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                ( {pending} ) {(selectedSourceWallet && selectedSourceWallet.assetTicker) || ""} Pending
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">TRANSFER AMOUNT</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                {formatCryptoQuantity(
                  Number(transferRequest.quantity) || 0,
                  selectedSourceWallet.assetTicker || "",
                  "long",
                  getDecimalLength(Number(transferRequest.quantity)),
                )}{" "}
                {(selectedSourceWallet && selectedSourceWallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(
                    transferRequest.assetTicker as string,
                    Number(transferRequest.quantity),
                    priceFeed,
                    assets,
                  ) || 0,
                )}{" "}
                USD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container justifyContent={"space-between"} sx={{ mt: 1 }}>
            <Grid>
              <Typography variant="subtitle2">UPDATED BALANCE</Typography>
            </Grid>
            <Grid direction="row" sx={{ textAlign: "right" }}>
              <Typography variant="subtitle1">
                <QuantityDisplay quantity={newAssetBalance || 0} ticker={selectedSourceWallet.assetTicker || ""} />{" "}
                {(selectedSourceWallet && selectedSourceWallet.assetTicker) || ""}
              </Typography>
              <Typography variant="subtitle1">
                {formatDollarAmountUsd(
                  getPriceByAssetQty(
                    transferRequest.assetTicker as string,
                    Number(newAssetBalance),
                    priceFeed,
                    assets,
                  ) || 0,
                )}{" "}
                USD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
