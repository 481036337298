import { RootContextType, useRootContext } from "../../RootLayout";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CreateTransactionRequest, CreateTransactionRequestTypeEnum, Wallet } from "../../services/openAPI/client";
import {
  Button,
  color,
  DialogTitle,
  Divider,
  formatWalletAddress,
  Icons,
  TextField,
  Typography,
} from "@bakkt/bakkt-ui-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
  Unstable_Grid2 as Grid,
  Container,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  getAssetQtyByPrice,
  getAvailableAndPendingBalances,
  getSVGStringForTicker,
  tickerToAssetEnum,
} from "../../utils/dataUtils";
import { CryptoTickerEnum } from "../../utils/CryptoIconsMap";
import { WalletBalanceAvailableOnly } from "./balances/WalletBalanceAvailableOnly";
import { WalletBalanceAvailableMinimal } from "./balances/WalletBalanceAvailableMinimal";
import ReviewTransferDetails from "./ReviewTransferDetails";
import { WalletTransactionService } from "../../services/serviceLoader";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export interface TransferFormData {
  assetTicker?: string | null;
  fromWalletId?: number | null;
  quantity?: number | null;
  accountId?: number;
  organizationId?: number;
  clientName?: string;
  orgName?: string;
  requesterName?: string;
  destinationWalletId: number | null;
  originatorId?: number;
  createdOn?: number;
}

export default function WalletTransfer() {
  const fetcher = useFetcher();
  const { orgDataCache, selectedOrg, userInfo, priceFeed, setShouldRefreshPolicyItems, addAlert, assets } =
    useRootContext() as RootContextType;
  const accountOmnibusWallets = orgDataCache.accountOmnibusWallets;
  const orgOmnibusWallets = orgDataCache.omnibusWallets;
  const accountOrgs = orgDataCache.accountOrgs;
  const { walletId: sourceWalletId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const buttonToggleStyle = {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
  };

  const blankTransferFormData: TransferFormData = {
    assetTicker: null,
    fromWalletId: null,
    quantity: null,
    accountId: userInfo.accountId,
    organizationId: selectedOrg.id,
    destinationWalletId: null,
  };

  const [selectedSourceWallet, setSelectedSourceWallet] = useState<Wallet | null>();
  const [selectedDestinationWallet, setSelectedDestinationWallet] = useState<Wallet | null>();
  const [transferFormData, setTransferFormData] = useState<TransferFormData>(blankTransferFormData);
  const [transferStep, setTransferStep] = useState<number>(1);
  const [currencyView, setCurrencyView] = useState<string>("CRYPTO");
  const [destinationWalletList, setDestinationWalletList] = useState<Wallet[]>();

  const filterDestinationByOrgAndTicker = (selectedSourceWallet: Wallet | undefined) => {
    if (!selectedSourceWallet) {
      return;
    } else {
      const destinationWalletList = (accountOmnibusWallets || [])
        .filter(
          (wallet) =>
            wallet.assetTicker === selectedSourceWallet.assetTicker &&
            wallet.walletId !== selectedSourceWallet?.walletId,
        )
        .sort((a: Wallet, b: Wallet) => ((a.name ? a.name : "") < (b.name ? b.name : "") ? -1 : 1)) as Wallet[];
      setDestinationWalletList(destinationWalletList);
    }
  };

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const [balances, setBalances] = useState({
    availableBalanceCrypto: 0,
    availableBalanceUsd: 0,
  });

  const handleReviewTransfer = () => {
    // before we progress, check if amount needs converted from
    // input USD value to crypto value
    let amount = Number(transferFormData.quantity);
    if (currencyView !== "CRYPTO") {
      // get asset QTY from dollar value & assetTicker
      amount = getAssetQtyByPrice(transferFormData.assetTicker || "", amount, priceFeed, assets);
      // convert back to string
      updateField("quantity", amount.toString());
    }
    setTransferStep(transferStep + 1);
  };

  const updateField = (fieldName: string, value: string | number | boolean | null) => {
    setTransferFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const resetValues = () => {
    setSelectedDestinationWallet(null);
    updateField("destinationWalletId", null);
    updateField("toWalletAddress", null);
    updateField("quantity", null);
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "success",
          messageHeader: "Transfer transaction has been initiated.",
        });
      } else {
        setShouldRefreshPolicyItems(false);
        addAlert({
          severity: "error",
          messageHeader: "Error while initiating transfer transaction.",
          message: `${response.message}: ${response.data.message}`,
        });
      }
      navigate(`/${selectedOrg.id}`);
    }
  }, [fetcher.data]);

  useEffect(() => {
    if (sourceWalletId) {
      const selectedSource = orgOmnibusWallets.find((wallet) => wallet.walletId === Number(sourceWalletId));
      setSelectedSourceWallet(selectedSource);
      if (selectedSource) {
        updateSourceWalletChanged(selectedSource);
      }
    }
  }, []);

  const handleChangeSourceWallet = (event: SelectChangeEvent) => {
    const newSelectedSourceWallet = orgOmnibusWallets.find(
      (wallet) => wallet.walletId === parseInt(event.target.value),
    );

    if (newSelectedSourceWallet) {
      setSelectedSourceWallet(newSelectedSourceWallet);
      updateSourceWalletChanged(newSelectedSourceWallet);
    }
    // clear destination wallet and amount
    resetValues();
  };

  const updateSourceWalletChanged = (newSelectedSourceWallet: Wallet) => {
    //calculate new source wallet balances
    setBalances(getAvailableAndPendingBalances(newSelectedSourceWallet, priceFeed, assets));

    updateField("fromWalletId", newSelectedSourceWallet?.walletId || "");
    updateField("assetTicker", newSelectedSourceWallet?.assetTicker || "");

    //update destination wallet list
    filterDestinationByOrgAndTicker(newSelectedSourceWallet);
  };

  const handleChangeDestinationWallet = (event: SelectChangeEvent) => {
    const newSelectedDestinationWallet = accountOmnibusWallets.find(
      (wallet) => wallet.walletId === parseInt(event.target.value),
    );

    updateField("destinationWalletId", event.target.value);

    setSelectedDestinationWallet(newSelectedDestinationWallet);
    updateField("quantity", null);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
    const name = event.target.name;
    let value = event.target.value;
    if (currencyView === "CRYPTO") {
      if (value.startsWith(".")) {
        value = `0${value}`;
      } else if (value.startsWith("0") && !value.startsWith("0.") && value.length > 1) {
        value = `0.${value.substring(1)}`;
      }
    }

    const decimalRegex =
      currencyView === "CRYPTO" && selectedSourceWallet?.assetTicker === CryptoTickerEnum.BTC
        ? /^\d*(\.\d{0,8})?$/
        : /^\d*(\.\d{0,18})?$/;

    if (decimalRegex.test(value)) {
      updateField(name, value);
    }
  };

  const handleCurrencyToggle = (event: React.MouseEvent<HTMLElement>, currency: string | null) => {
    const value = event.target;
    setCurrencyView(currency as string);
  };

  const isTransferFormValid = () => {
    const isValid = (val: any) => {
      if (val) return true;
    };
    const isPositive = (val: any) => {
      if (Number(val) > 0) return true;
    };

    const isWithinAvailableBalance = (val: any) => {
      if (currencyView === "CRYPTO" && Number(val) <= balances.availableBalanceCrypto) {
        return true;
      } else if (currencyView !== "CRYPTO" && Number(val) <= balances.availableBalanceUsd) {
        return true;
      }
    };
    if (
      isValid(transferFormData?.fromWalletId) &&
      isValid(transferFormData?.quantity) &&
      isPositive(transferFormData?.quantity) &&
      isWithinAvailableBalance(transferFormData?.quantity) &&
      isValid(transferFormData?.assetTicker) &&
      isValid(transferFormData?.destinationWalletId)
    )
      return true;
  };

  const handleCreateTransfer = () => {
    const createTransfer: CreateTransactionRequest = {
      type: CreateTransactionRequestTypeEnum.Transfer,
      sourceWalletId: Number(transferFormData.fromWalletId),
      destinationWalletId: Number(transferFormData.destinationWalletId),
      quantity: Number(transferFormData.quantity),
    };
    fetcher.submit(JSON.stringify(createTransfer), {
      method: "post",
      encType: "application/json",
    });
  };

  const bodyHeadingSx = {
    color: color.text.secondary,
    fontWeight: "bold",
  };

  function getDisplayTextSource(wallet: Wallet | null, isSelected?: boolean) {
    const balances = getAvailableAndPendingBalances(wallet, priceFeed, assets);
    const cryptoBalance = balances.availableBalanceCrypto || 0;
    if (!wallet) {
      return <></>;
    }
    return (
      <Grid container direction={"column"}>
        <Grid container alignItems={"center"} sx={{ pb: 0 }}>
          <SvgIcon
            component={() =>
              AssetSVGIcon({
                svgString: getSVGStringForTicker(assets, wallet?.assetTicker),
                title: wallet?.assetTicker,
                sx: { width: 30, height: 30, mb: -1 },
              })
            }
            inheritViewBox
          />
          <Typography variant="h5">{tickerToAssetEnum[wallet?.assetTicker || ""]}</Typography>
          <Typography variant="h5"> &nbsp;</Typography>
          <Typography variant={"subtitle1"}> {`(${selectedOrg.name})`}</Typography>
        </Grid>
        <Grid container alignItems={"center"} sx={{ pl: 4 }}>
          <Typography variant={"body1"}>{`ID: ${formatWalletAddress(wallet?.address || "")}`}</Typography>
        </Grid>
        {!isSelected && (
          <Grid container alignItems={"center"} sx={{ pl: 4 }}>
            <Typography variant={"body1"}>{`Balance: ${cryptoBalance} ${wallet?.assetTicker}`}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  function getDisplayTextDestination(wallet: Wallet | null) {
    if (!wallet) {
      return <></>;
    }
    return (
      <Grid container direction={"column"}>
        <Grid container alignItems={"center"} sx={{ pb: 0 }}>
          <Typography variant={"h5"}>
            {" "}
            {`${accountOrgs ? accountOrgs.find((org) => org.id === wallet?.organizationId)?.name : ""}`}
          </Typography>
        </Grid>
        <Grid container alignItems={"center"} sx={{ pl: 0 }}>
          <Typography variant={"body1"}>{`${formatWalletAddress(wallet?.address || "")}`}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={transferStep === 1 ? "md" : "sm"} fullWidth={false}>
      {transferStep == 1 && (
        <>
          <DialogTitle title="Transfer" severity="secondary">
            Specify your transfer details below. Transfers can only be sent to internal omnibus Bakkt wallets belonging
            to organizations within your account.
          </DialogTitle>
          <DialogContent>
            <Container disableGutters maxWidth={"lg"}>
              <Grid container direction={"row"} columnGap={2}>
                <Grid container xs={7} direction={"column"}>
                  <Grid xs={12} sx={{ mb: 8 }}>
                    <FormControl variant="standard" sx={{ width: "100%" }} required>
                      <InputLabel id="select-wallet">Source omnibus wallet</InputLabel>
                      <Select
                        name="fromWalletId"
                        value={selectedSourceWallet?.walletId ? (selectedSourceWallet?.walletId as any) : ""}
                        renderValue={() => getDisplayTextSource(selectedSourceWallet || null, true)}
                        label="Wallet"
                        onChange={handleChangeSourceWallet}
                        MenuProps={{
                          sx: { maxHeight: 300 },
                        }}
                      >
                        {orgOmnibusWallets.map((wallet) => (
                          <MenuItem key={wallet.walletId} value={wallet.walletId}>
                            {getDisplayTextSource(wallet)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="h5">Destination</Typography>

                    <Grid container xs={12} alignItems={"flex-end"} justifyContent={"space-between"} sx={{ mb: 3 }}>
                      <FormControl variant="standard" sx={{ width: "100%" }} required>
                        <InputLabel id="select-destination-wallet">Destination</InputLabel>
                        <Select
                          name="destinationWalletId"
                          id="destination"
                          value={
                            selectedDestinationWallet?.walletId ? (selectedDestinationWallet?.walletId as any) : ""
                          }
                          label="Destination wallet"
                          renderValue={() => getDisplayTextDestination(selectedDestinationWallet || null)}
                          onChange={handleChangeDestinationWallet}
                          disabled={!selectedSourceWallet}
                          MenuProps={{
                            sx: { maxHeight: 300 },
                          }}
                        >
                          {destinationWalletList && destinationWalletList?.length < 1 ? (
                            <MenuItem key={0} value={""}>
                              <Box>
                                <Typography variant="h5">There are no Omnibus wallets to transfer to</Typography>
                              </Box>
                            </MenuItem>
                          ) : (
                            destinationWalletList?.map((wallet) => (
                              <MenuItem key={wallet.walletId} value={wallet.walletId}>
                                {getDisplayTextDestination(wallet)}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid container xs={12} alignItems={"flex-end"} justifyContent={"space-between"} sx={{ mb: 3 }}>
                      <FormControl sx={{ width: "82%" }} variant="standard" required>
                        <TextField
                          name="quantity"
                          InputProps={{
                            startAdornment: selectedDestinationWallet ? (
                              <InputAdornment position="start">
                                {currencyView === "CRYPTO" ? selectedSourceWallet?.assetTicker : "USD $"}
                              </InputAdornment>
                            ) : null,
                          }}
                          value={transferFormData.quantity ? (transferFormData.quantity as any) : ""}
                          onChange={handleInputChange}
                          label="Amount"
                          variant="standard"
                          required
                          disabled={!selectedDestinationWallet}
                        />
                      </FormControl>
                      <FormControl>
                        <ToggleButtonGroup
                          value={selectedDestinationWallet ? currencyView : ""}
                          exclusive
                          onChange={handleCurrencyToggle}
                          aria-label="currency-view"
                          disabled={!selectedDestinationWallet}
                        >
                          <ToggleButton value="CRYPTO" aria-label="left aligned" sx={buttonToggleStyle}>
                            <SvgIcon
                              component={Icons.CryptoIcon}
                              inheritViewBox
                              sx={{ width: "18px", height: "18px" }}
                            />
                          </ToggleButton>
                          <ToggleButton value="USD" aria-label="centered" sx={buttonToggleStyle}>
                            <SvgIcon component={Icons.CashIcon} inheritViewBox sx={{ width: "18px", height: "18px" }} />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </Grid>

                    <Grid>
                      <WalletBalanceAvailableMinimal
                        selectedAsset={selectedSourceWallet?.assetSymbol || ""}
                        ticker={selectedSourceWallet?.assetTicker || "BTC"}
                        amount={(transferFormData?.quantity || "") as string}
                        view={currencyView}
                        disabled={transferFormData?.quantity ? false : true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid container xs={4} direction={"column"}>
                  <Grid container direction={"column"}>
                    {selectedSourceWallet ? (
                      <>
                        <WalletBalanceAvailableOnly walletId={selectedSourceWallet?.walletId?.toString() as string} />
                      </>
                    ) : (
                      <>
                        <Typography variant="body2" sx={bodyHeadingSx}>
                          AMOUNT AVAILABLE
                        </Typography>
                        <Typography variant="h5">Wallet Not Selected</Typography>
                        <Typography variant="subtitle1">$0.00</Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button variant={"contained"} onClick={handleReviewTransfer} disabled={!isTransferFormValid()} autoFocus>
              Review Transfer
            </Button>
          </DialogActions>
        </>
      )}
      {transferStep == 2 && (
        <>
          <DialogTitle title="Review Transfer" severity="warning">
            Review your transfer details below.
          </DialogTitle>
          <DialogContent>
            <ReviewTransferDetails transferRequest={transferFormData} />
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button variant={"contained"} autoFocus onClick={handleCreateTransfer}>
              Create Transfer Request
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export async function action({ request }: { request: Request }) {
  try {
    const createTransferTransaction = (await request.json()) as CreateTransactionRequest;
    const createTransferResponse = await WalletTransactionService.createTransaction(createTransferTransaction);
    return formatActionSuccessResponse(createTransferResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
