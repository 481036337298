import { Alert, Button, Dialog, DialogTitle, Icons, TextField, Typography } from "@bakkt/bakkt-ui-components";
import {
  ClickAwayListener,
  Container,
  DialogActions,
  DialogContent,
  IconButton,
  SelectChangeEvent,
  Skeleton,
  Stack,
  SvgIcon,
  Tooltip,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Await,
  defer,
  Link as RouterLink,
  LoaderFunctionArgs,
  Params,
  useFetcher,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PriceInfo, Wallet, WalletTransaction } from "../../services/openAPI/client";
import WalletDetailsTransGrid from "./WalletDetailsTransGrid";
import {
  checkWarmBalance,
  getSVGStringForTicker,
  getScanLink,
  mapWalletFormDataToWalletRequest,
  shouldUseMockData,
} from "../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, walletTransactions } from "../../services/mockData";
import { WalletService, WalletTransactionService } from "../../services/serviceLoader";
import { useRootContext } from "../../RootLayout";
import { WalletDetailsBalances } from "./balances/WalletDetailsBalances";
import LoadingIndicator from "../../components/loading/LoadingIndicator";
import { isInitiator, isViewOnly } from "../../utils/permissionsUtil";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export interface EditWalletFormData {
  name: string;
  description?: string;
}

export default function WalletDetailsDialog() {
  const navigate = useNavigate();
  const routeParams = useParams();
  const { walletTransactionsPromise } = useLoaderData() as {
    walletTransactionsPromise: Promise<WalletTransaction[]>;
  };
  const { userInfo, orgDataCache, selectedOrg, priceFeed, assets, networks } = useRootContext();
  const wallets = orgDataCache.wallets;
  const wallet = wallets.find((wallet) => wallet.walletId === Number(routeParams.walletId));
  const fetcher = useFetcher();

  const editWalletFormData: EditWalletFormData = {
    name: wallet?.name || "",
    description: wallet?.description || "",
  };

  const [open, setOpen] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const isSaving = fetcher.state === "submitting";
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [editWallet, setEditWallet] = useState<EditWalletFormData>(editWalletFormData);
  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState(false);
  const handleTooltipClose = () => {
    setShowCopyAddressConfirm(false);
  };
  const isWarmBalance = checkWarmBalance(wallets, priceFeed as PriceInfo[], assets);

  const copyAddress = () => {
    navigator.clipboard.writeText(wallet?.address || "");
    setShowCopyAddressConfirm(true);
  };

  const scanLink = getScanLink(wallet?.assetSymbol || "", wallet?.address || "", assets, networks);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        setShowSuccess(true);
      } else {
        setShowFailure(true);
      }
    }
  }, [fetcher.data]);

  const handleClose = () => {
    setOpen(false);
    setShowSuccess(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setShowEdit((prev) => !prev);
  };

  const showEditForm = () => {
    setShowSuccess(false);
    setShowEdit((prev) => !prev);
  };

  const updateField = (fieldName: string, value: string) => {
    setEditWallet((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSaveWallet = async () => {
    setShowSuccess(false);
    setShowFailure(false);
    setShowEdit((prev) => !prev);
    const formWalletRequest = mapWalletFormDataToWalletRequest(editWallet);
    fetcher.submit(JSON.stringify(formWalletRequest), {
      method: "post",
      encType: "application/json",
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
    const { name, value } = event.target;
    updateField(name, value);
  };

  const isFormValid = () => {
    return editWallet.name !== "";
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <DialogTitle title={`Wallet Details - ${wallet && wallet.walletId}`} severity="secondary">
        {wallet && isWarmBalance ? wallet.temperature : ""}
        {wallet ? " " + wallet.network + " wallet" : ""}
      </DialogTitle>

      <DialogContent sx={{ overflow: showEdit ? "hidden" : "auto", minWidth: "1024px" }}>
        {showSuccess && (
          <Alert severity="success" sx={{ mb: 4 }}>
            <Typography variant="body1">Success</Typography>
            <Typography variant="body2">Your changes are saved.</Typography>
          </Alert>
        )}

        {showFailure && (
          <Alert severity="error" sx={{ mb: 4 }}>
            <Typography variant="body1">Error</Typography>
            <Typography variant="body2">There was a problem with your request. Your changes were not saved.</Typography>
          </Alert>
        )}

        {!showEdit && (
          <Grid container sx={{ justifyContent: "space-between" }}>
            {isSaving ? (
              <Grid xs={12}>
                <Stack sx={{ mt: 12, mb: 7 }}>
                  <LoadingIndicator />
                </Stack>
              </Grid>
            ) : (
              <>
                <Grid xs={10}>
                  <Typography variant="h5">{wallet?.name}</Typography>
                  <Typography variant="body1">{wallet?.description}</Typography>
                </Grid>
                <Grid container>
                  {!isViewOnly(userInfo, selectedOrg.id) && (
                    <Button variant="text" onClick={showEditForm}>
                      Edit
                    </Button>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        )}

        {showEdit && (
          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField
                name="name"
                value={editWallet.name}
                onChange={handleInputChange}
                label="Wallet Name"
                variant="standard"
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                name="description"
                value={editWallet.description}
                onChange={handleInputChange}
                label="Wallet Description (Optional)"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <Button variant={"outlined"} onClick={handleCancel} sx={{ mr: 0.8 }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSaveWallet} autoFocus disabled={!isFormValid()}>
                Save
              </Button>
            </Grid>
          </Grid>
        )}

        <Container disableGutters sx={{ opacity: showEdit ? "0.3" : "1" }}>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid xs={8}>
              <Typography variant="h5" sx={{ mb: 0.6 }}>
                Wallet Address
              </Typography>
              <Grid container sx={{ justifyContent: "flex-start", alignItems: "center" }}>
                <SvgIcon
                  component={() =>
                    AssetSVGIcon({
                      svgString: getSVGStringForTicker(assets, wallet?.assetTicker as string),
                      title: wallet?.assetTicker as string,
                      sx: { mr: 1 },
                    })
                  }
                  inheritViewBox
                />
                <Typography variant="body3">{wallet && wallet.address}</Typography>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={showCopyAddressConfirm}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title="Address Copied"
                  >
                    <IconButton sx={{ m: 0, p: 0 }} onClick={copyAddress} disableRipple disableFocusRipple>
                      <SvgIcon
                        component={Icons.CopyIcon}
                        inheritViewBox
                        sx={{ width: "15px", height: "15px", ml: 1, mr: 1.2 }}
                      />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
                <IconButton
                  sx={{ m: 0, p: 0 }}
                  href={scanLink}
                  disabled={showEdit}
                  target="_blank"
                  disableRipple={true}
                >
                  <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={{ width: "16px", height: "16px" }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid xs={4} sx={{ textAlign: "right", mt: "16px" }}>
              <RouterLink
                to={
                  isInitiator(userInfo, Number(routeParams.organizationId))
                    ? `../withdraw/${routeParams.walletId}`
                    : "#"
                }
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ mr: 0.8 }}
                  disabled={showEdit || !isInitiator(userInfo, Number(routeParams.organizationId))}
                >
                  WITHDRAW
                </Button>
              </RouterLink>
              <RouterLink to={`../deposit/${routeParams.walletId}`}>
                <Button variant="contained" color="primary" disabled={showEdit}>
                  DEPOSIT
                </Button>
              </RouterLink>
            </Grid>
          </Grid>

          <Grid container spacing={0.3} sx={{ mt: 2.5 }}>
            <React.Suspense
              fallback={
                <Grid xs={12}>
                  <Skeleton variant="rectangular" height={110} />
                </Grid>
              }
            >
              <Await
                resolve={walletTransactionsPromise}
                errorElement={<Alert severity="error">Error loading transaction data!</Alert>}
              >
                <WalletDetailsBalances />
              </Await>
            </React.Suspense>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid xs={12}>
              <Typography variant="h5">Pending Transactions</Typography>

              <React.Suspense fallback={<Skeleton variant="rectangular" height={110} />}>
                <Await
                  resolve={walletTransactionsPromise}
                  errorElement={<Alert severity="error">Error loading transaction data!</Alert>}
                >
                  <WalletDetailsTransGrid />
                </Await>
              </React.Suspense>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const orgId = Number(params.organizationId);
    const walletId = Number(params.walletId);

    const walletTransactionsPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(walletTransactions, 2000)
      : WalletTransactionService.getWalletTransactions(walletId, orgId);

    return defer({
      walletTransactionsPromise: walletTransactionsPromise,
    });
  } catch (e) {
    console.log("Error fetching transaction data: ", e);
    return {};
  }
}

export async function action({ request, params }: { request: Request; params: Params }) {
  const formWalletRequest = (await request.json()) as Wallet;
  const walletId = Number(params.walletId);
  try {
    const walletSaveResponse = await WalletService.updateWalletById(walletId, formWalletRequest);
    return formatActionSuccessResponse(walletSaveResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
