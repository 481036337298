import { RootContextType, useRootContext } from "../../../RootLayout";
import { Await, Link as RouterLink } from "react-router-dom";
import React, { useContext } from "react";
import { AllowlistAddress, WalletType } from "../../../services/openAPI/client";
import { Button, color, formatWalletAddress, Icons, TextField, Typography } from "@bakkt/bakkt-ui-components";
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Unstable_Grid2 as Grid,
  Box,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WalletBalanceAvailableMinimal } from "../balances/WalletBalanceAvailableMinimal";
import { destinationType, withdrawToType } from "../../../utils/customTypes";
import { isREL009PublicFeature } from "../../../config/firebaseConfig.ts";
import { withdrawContext } from "./WithdrawContext.tsx";

function WithdrawDestinationWallet() {
  const withdrawCtx = useContext(withdrawContext);

  const {
    policyAllowlistListPromise,
    handleCurrencyToggle,
    selectedAsset,
    currencyView,
    updateField,
    addressList,
    filterAddressesByAsset,
    withdraw,
    selectedWalletType,
    selectedWallet,
    allowListedAddresses,
    destinationAddressType,
    setDestinationAddressType,
    handleInputChange,
    selectedAddress,
    selectedAddressId,
    setSelectedAddress,
    setSelectedAddressId,
    setSelectDestinationType,
  } = withdrawCtx;

  const { orgDataCache, selectedOrg } = useRootContext() as RootContextType;
  const wallets = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets];
  const accountOrgs = orgDataCache.accountOrgs;

  const theme = useTheme();

  const buttonToggleStyle = {
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
  };

  const getSelectedAddressById = (addressId: number): AllowlistAddress => {
    return addressList?.find((address) => address.id == addressId) as AllowlistAddress;
  };

  const getAllowlistedById = (addressItem: number) => {
    return allowListedAddresses?.find((address) => address.id == addressItem) as AllowlistAddress;
  };

  const handleChangeOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    // set the ownertype
    const destination = event.target.value;

    if (destination === destinationType.Trading) {
      setSelectDestinationType(withdrawToType.Wallet);
    } else {
      setSelectDestinationType(withdrawToType.Allowlist);
    }

    setDestinationAddressType(destination);
    // reset selected address values & formData
    setSelectedAddress(null);
    setSelectedAddressId(null);

    updateField("toWalletAddress", "");
    updateField("toWalletId", "");
    updateField("destinationType", destination);
  };

  const handleChangeAddress = (event: SelectChangeEvent) => {
    // set allowList values & formData
    const allowListItem = getAllowlistedById(Number(event.target.value)) as AllowlistAddress;
    updateWithdrawWalletDestinationFields(Number(event.target.value), allowListItem);
  };

  const updateWithdrawWalletDestinationFields = (id: number, allowlist: AllowlistAddress, type?: string) => {
    //find destination wallet
    const destinationWallet = wallets.find((wallet) =>
      type === destinationType.Trading
        ? wallet.depositAddress === allowlist?.address
        : wallet.address === allowlist?.address,
    );

    setSelectedAddress(getSelectedAddressById(id) as AllowlistAddress);
    setSelectedAddressId(id);

    updateField("toWalletAddress", allowlist?.address);
    updateField("allowListId", allowlist?.id as number);
    updateField("allowListName", allowlist?.name as string);
    updateField("toWalletId", destinationWallet?.walletId as number);
  };

  const disableOmnibus = orgDataCache.omnibusWallets.length === 0;

  function getDisplayTextDestination(address: AllowlistAddress | null) {
    if (!address) {
      return;
    }
    return (
      <Grid container direction={"column"}>
        <Grid container alignItems={"center"} sx={{ pb: 0 }}>
          <Typography variant={"h5"}>
            {`${accountOrgs ? accountOrgs.find((org) => org.id === address?.organizationId)?.name : ""}`}
          </Typography>
        </Grid>
        <Grid container alignItems={"center"} sx={{ pl: 0 }}>
          <Typography variant={"body1"}>{`${formatWalletAddress(address?.tradingAddress || "")}`}</Typography>
        </Grid>
      </Grid>
    );
  }

  function getDisplayTextDestinationWrapper(
    selectedAddress: AllowlistAddress | null,
    selectedAddressId: number | null,
    destinationAddressType: string,
  ) {
    if (selectedAddressId && destinationAddressType === destinationType.Trading) {
      return getDisplayTextDestination(selectedAddress || null);
    } else {
      return selectedAddress?.address;
    }
  }
  return (
    <Grid container direction={"column"} justifyContent={"space-between"}>
      {selectedWallet && (
        <>
          <Typography variant="h5">Destination</Typography>

          <Grid sx={{ mb: 3 }}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="owner-label"
                name="owner"
                onChange={handleChangeOwner}
                value={destinationAddressType}
              >
                <FormControlLabel
                  value={destinationType.Internal}
                  control={<Radio />}
                  label={isREL009PublicFeature ? "Segregated Internal" : selectedOrg.name}
                />
                <FormControlLabel
                  value={destinationType.External}
                  control={<Radio />}
                  label={
                    isREL009PublicFeature ? "Segregrated External Wallet" : "External Entity or Financial Institution"
                  }
                />
                {isREL009PublicFeature && selectedWalletType === WalletType.Custody && (
                  <Stack direction={"row"} alignContent={"flex-end"} alignItems={"flex-end"} sx={{ p: 0, m: 0 }}>
                    <FormControlLabel
                      value={destinationType.Trading}
                      control={<Radio />}
                      label="Omnibus Wallet"
                      sx={{ mr: 0 }}
                      disabled={disableOmnibus}
                    />
                    {disableOmnibus && (
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Please activate omnibus in the dashboard in order to create omnibus wallets.
                          </Typography>
                        }
                        placement="top"
                      >
                        <Box>
                          <SvgIcon
                            component={Icons.InformationIcon}
                            sx={{ width: 22, height: 22, color: color.text.disabled }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Stack>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid container xs={12} alignItems={"flex-end"} justifyContent={"space-between"} sx={{ mb: 3 }}>
            <Grid xs={destinationAddressType === destinationType.External ? 10 : 12}>
              <React.Suspense>
                <Await resolve={policyAllowlistListPromise} errorElement={<></>}>
                  <>
                    <FormControl variant="standard" sx={{ width: "100%" }} required>
                      <InputLabel id="select-address">Address</InputLabel>
                      <Select
                        name="toWalletAddress"
                        id="address"
                        value={selectedAddressId ? (selectedAddressId as any) : ""}
                        label="Address"
                        renderValue={() =>
                          getDisplayTextDestinationWrapper(
                            selectedAddress || null,
                            selectedAddressId,
                            destinationAddressType,
                          )
                        }
                        onChange={handleChangeAddress}
                        disabled={!destinationAddressType}
                        MenuProps={{
                          sx: { maxHeight: 300 },
                        }}
                      >
                        {filterAddressesByAsset(selectedAsset, addressList)?.length < 1 ? (
                          <MenuItem key={0} value={""}>
                            <Box>
                              <Typography variant="h5">No Approved Addresses For This Selection</Typography>
                            </Box>
                          </MenuItem>
                        ) : (
                          filterAddressesByAsset(selectedAsset, addressList)?.map((address: AllowlistAddress) => (
                            <MenuItem key={address.id} value={address.id}>
                              <Box>
                                {!address.tradingAddress && <Typography variant="h5">{`${address.name}`}</Typography>}
                                {destinationAddressType === destinationType.Trading
                                  ? getDisplayTextDestination(address)
                                  : `Address:${address?.address}`}
                              </Box>
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </>
                </Await>
              </React.Suspense>
            </Grid>

            {destinationAddressType === destinationType.External && (
              <RouterLink to={`/allowlist/${Number(selectedOrg.id)}`}>
                <Button variant="outlined" size="small" sx={{ pointerEvents: "auto" }} data-testid="newAllowButton">
                  <SvgIcon component={Icons.PlusIcon} sx={{ width: "20px", height: "20px", marginRight: "6px" }} />
                  Add Address
                </Button>
              </RouterLink>
            )}
          </Grid>

          <Grid container xs={12} alignItems={"flex-end"} justifyContent={"space-between"} sx={{ mb: 3 }}>
            <Grid xs={10}>
              <FormControl fullWidth variant="standard" required>
                <TextField
                  name="quantity"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencyView === "CRYPTO" ? selectedWallet?.assetTicker : "USD $"}
                      </InputAdornment>
                    ),
                  }}
                  value={withdraw?.quantity}
                  onChange={handleInputChange}
                  label="Amount"
                  variant="standard"
                  required
                />
              </FormControl>
            </Grid>

            <FormControl>
              <ToggleButtonGroup
                value={currencyView}
                exclusive
                onChange={handleCurrencyToggle}
                aria-label="currency-view"
              >
                <ToggleButton value="CRYPTO" aria-label="left aligned" sx={buttonToggleStyle}>
                  <SvgIcon component={Icons.CryptoIcon} inheritViewBox sx={{ width: "18px", height: "18px" }} />
                </ToggleButton>
                <ToggleButton value="USD" aria-label="centered" sx={buttonToggleStyle}>
                  <SvgIcon component={Icons.CashIcon} inheritViewBox sx={{ width: "18px", height: "18px" }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Grid>

          <Grid>
            <WalletBalanceAvailableMinimal
              selectedAsset={selectedAsset}
              ticker={selectedWallet?.assetTicker as string}
              amount={withdraw?.quantity as string}
              view={currencyView as string}
            />
            <Typography variant="body2">Maximum Withdraw: $20,000,000</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default WithdrawDestinationWallet;
