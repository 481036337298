import { Asset, Wallet } from "../../services/openAPI/client";
import { Alert, Typography } from "@bakkt/bakkt-ui-components";
import { getMinimunFeeAmount } from "../../utils/dataUtils";

interface TokenAssetBannerProps {
  gasBalance: number;
  feeWallet: Wallet | null;
  assets: Asset[];
}

function TokenAssetBanner({ gasBalance, feeWallet, assets }: TokenAssetBannerProps) {
  return (
    <Alert severity="warning" sx={{ mb: 2 }}>
      <Typography variant={"subtitle2"}>
        This {feeWallet?.network} asset’s fee wallet has insufficient funds of {gasBalance} {feeWallet?.assetTicker}.
        <Typography variant={"subtitle2"}>
          {feeWallet?.network} assets require a minimum balance of{" "}
          {getMinimunFeeAmount(feeWallet?.assetTicker || "", assets)} {feeWallet?.assetTicker} in {feeWallet?.name}{" "}
          wallet for GAS fees.
        </Typography>
        <Typography variant={"subtitle2"}>
          *Please fund this fee wallet {feeWallet?.address} with at least{" "}
          {getMinimunFeeAmount(feeWallet?.assetSymbol || "", assets)} {feeWallet?.assetTicker} in order to proceed
        </Typography>
      </Typography>
    </Alert>
  );
}

export default TokenAssetBanner;
