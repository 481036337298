import { useAsyncValue, Link as RouterLink } from "react-router-dom";
import { Icons, formatCurrency, color } from "@bakkt/bakkt-ui-components";
import {
  Box,
  SvgIcon,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Stack,
} from "@mui/material";
import React from "react";
import { useRootContext } from "../../RootLayout.tsx";
import {
  convertPricingInfoArrayIntoMap,
  getMarketPriceForWallet,
  getPriceByAssetQty,
  getSVGStringForTicker,
  sortWalletsByAssetId,
} from "../../utils/dataUtils.ts";
import QuantityDisplay from "../../components/quantityDisplay/QuantityDisplay.tsx";
import { Wallet } from "../../services/openAPI/client/api.ts";
import { isInitiator } from "../../utils/permissionsUtil.ts";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export function OffExchangeWalletsGrid() {
  const wallets: any = useAsyncValue() as Wallet[];
  const { priceFeed, selectedOrg, userInfo, assets, orgDataCache } = useRootContext();
  const selectedOrgOffExchangeWallets = orgDataCache.offExchangeWallets;

  const pricingInfoMap = convertPricingInfoArrayIntoMap(priceFeed, assets);

  const tableHeader = {
    color: color.techBlack["700"],
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "normal",
    fontWeight: 500,
    noWrap: "true",
  };

  const linkTextSx = {
    textDecoration: "underline",
  };

  const getTickerForWallet = (wallet: Wallet) => {
    return assets.find((asset) => asset.symbol === wallet.assetSymbol)?.ticker || "";
  };

  return (
    <>
      {selectedOrgOffExchangeWallets.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" sx={[tableHeader, { pl: 1 }]} width="250">
                  ASSET
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader} width="250">
                  TOTAL BALANCE
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader} width="300">
                  TRADABLE BALANCE
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader} width="200">
                  CREDIT
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader}>
                  COLLATERAL
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader} width="250">
                  MARKET PRICE
                </TableCell>
                <TableCell component="th" align="right" sx={tableHeader}>
                  <Stack direction={"row"} sx={{ mt: 1, mr: -1 }}>
                    EXCHANGE
                    <Tooltip title={<Typography variant="body2">Exchange name</Typography>} placement="top">
                      <Box>
                        <SvgIcon component={Icons.InformationIcon} sx={{ width: 25, height: 25 }} />
                      </Box>
                    </Tooltip>
                  </Stack>
                </TableCell>
                <TableCell component="th" align="right" sx={[tableHeader, { pr: 6 }]} width="270">
                  ACTIONS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortWalletsByAssetId(selectedOrgOffExchangeWallets, assets).map((wallet: Wallet, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" sx={{ pl: 0.2 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <SvgIcon
                          component={() =>
                            AssetSVGIcon({
                              svgString: getSVGStringForTicker(assets, wallet.assetTicker),
                              title: wallet.assetTicker,
                              sx: { width: 25, height: 25, mx: 1 },
                            })
                          }
                          inheritViewBox
                        />
                        <Box>
                          <Typography variant="h5" sx={{ fontWeight: 500 }}>
                            <span>{assets.find((asset) => asset.symbol === wallet.assetSymbol)?.name || ""}</span>
                          </Typography>
                          <Typography variant="body2">{wallet.assetTicker as string}</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        <QuantityDisplay quantity={wallet.quantity} ticker={getTickerForWallet(wallet)} />{" "}
                        {getTickerForWallet(wallet)}
                      </Typography>
                      <Typography variant={"body2"}>
                        {formatCurrency(
                          getPriceByAssetQty(getTickerForWallet(wallet), wallet.quantity, priceFeed, assets),
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        <QuantityDisplay
                          quantity={wallet.availableBalance || 0.0}
                          ticker={getTickerForWallet(wallet)}
                        />{" "}
                        {getTickerForWallet(wallet)}
                      </Typography>
                      <Typography variant={"body2"}>
                        {formatCurrency(
                          getPriceByAssetQty(
                            getTickerForWallet(wallet),
                            wallet.availableBalance || 0.0,
                            priceFeed,
                            assets,
                          ),
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        <QuantityDisplay quantity={wallet.creditBalance || 0.0} ticker={getTickerForWallet(wallet)} />{" "}
                        {getTickerForWallet(wallet)}
                      </Typography>
                      <Typography variant={"body2"}>
                        {formatCurrency(
                          getPriceByAssetQty(
                            getTickerForWallet(wallet),
                            wallet.creditBalance || 0.0,
                            priceFeed,
                            assets,
                          ),
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">
                        <QuantityDisplay quantity={wallet.lockedBalance || 0.0} ticker={getTickerForWallet(wallet)} />{" "}
                        {getTickerForWallet(wallet)}
                      </Typography>
                      <Typography variant={"body2"}>
                        {formatCurrency(
                          getPriceByAssetQty(
                            getTickerForWallet(wallet),
                            wallet.lockedBalance || 0.0,
                            priceFeed,
                            assets,
                          ),
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{getMarketPriceForWallet(wallet, pricingInfoMap)}</TableCell>
                    <TableCell align="right">{wallet.offExchangeExternalAccountName}</TableCell>

                    <TableCell align="right" sx={{ pr: 5 }}>
                      <Grid justifyContent="space-between">
                        {isInitiator(userInfo, Number(selectedOrg.id)) && (
                          <>
                            <RouterLink
                              to={`collateral/${wallet.walletId}`}
                              state={{
                                symbol: wallet.assetSymbol,
                              }}
                            >
                              <Box component="span" sx={linkTextSx}>
                                Manage Collateral
                              </Box>
                            </RouterLink>{" "}
                          </>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
