import { DataGridPremium, GridColDef, GridPagination } from "@mui/x-data-grid-premium";
import { useAsyncValue } from "react-router-dom";
import { Icons, formatCurrency, formatHash, formatUnixTime, color } from "@bakkt/bakkt-ui-components";
import { Box, IconButton, Snackbar, SvgIcon, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRootContext } from "../../RootLayout";
import { formatCorrelationId, getPriceByAssetQty, getSVGStringForTicker, mapFauxIds } from "../../utils/dataUtils.ts";
import QuantityDisplay from "../../components/quantityDisplay/QuantityDisplay.tsx";
import { User, WalletTransactionStatus } from "../../services/openAPI/client/api.ts";
import { AssetSVGIcon } from "../../components/customSVG/AssetSVGIcon.tsx";

export function TransactionsGrid() {
  const { priceFeed, setOrgDataCache, orgDataCache, assets, networks } = useRootContext();
  const users = orgDataCache.users as User[];
  const [open, setOpen] = useState(false);
  const [copyItemLabel, setCopyItemLabel] = useState("");

  const handleClick = (copy: string, itemLabel: string) => {
    setOpen(true);
    setCopyItemLabel(itemLabel);
    navigator.clipboard.writeText(copy);
  };

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "DATE",
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return <span>{formatUnixTime(params.value, "long")}</span>;
        }
      },
      width: 180,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      editable: false,
      renderCell: (params) => {
        if (params.value === WalletTransactionStatus.Pending) {
          return (
            <Typography variant={"body2"} color={color.engagingOrange.dark}>
              {params.value}
            </Typography>
          );
        } else {
          return <span>{params.value}</span>;
        }
      },
    },
    {
      field: "type",
      headerName: "TYPE",
      flex: 1,
      editable: false,
      renderCell(params) {
        return params.value?.replaceAll("_", " ");
      },
    },
    {
      field: "assetTicker",
      headerName: "ASSET",
      width: 90,
      editable: false,
      renderCell: (params) => (
        <>
          <SvgIcon
            component={() =>
              AssetSVGIcon({
                svgString: getSVGStringForTicker(assets, params.value),
                title: params.value,
                sx: { width: 25, height: 25, pr: 1 },
              })
            }
            inheritViewBox
          />
          {params.value}
        </>
      ),
    },
    {
      field: "network",
      headerName: "NETWORK",
      width: 90,
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return "";
        } else {
          return (
            <Box>
              <Typography variant="body2">
                <QuantityDisplay quantity={params.value} ticker={params.row.assetTicker} /> {params.row.assetTicker}
              </Typography>
              <Typography variant={"body2"}>
                {formatCurrency(getPriceByAssetQty(params.row.assetTicker, params.value, priceFeed, assets))}
              </Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "fee",
      headerName: "FEES",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
    },
    {
      field: "amountUSD",
      headerName: "VALUE",
      sortable: false,
      width: 120,
      renderCell: (params) => <>{params.value ? formatCurrency(params.value) : ""}</>,
    },
    {
      field: "blockchainTransactionId",
      headerName: "HASH",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <IconButton aria-label="copy" onClick={() => handleClick(params.value, "hash")}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <span>{formatHash(params.value)}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "correlationId",
      headerName: "CORRELATION ID",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        if (params.value) {
          return (
            <>
              <IconButton aria-label="copy" onClick={() => handleClick(params.value, "correlation id")}>
                <SvgIcon component={Icons.CopyIcon} inheritViewBox sx={{ width: 15, height: 15 }} />
              </IconButton>
              <Tooltip title={params.value}>
                <span>{formatCorrelationId(params.value)}</span>
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "sourceName",
      headerName: "WALLET ORIGINATION",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,
    },
    {
      field: "destinationName",
      headerName: "DESTINATION",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,
    },
    {
      field: "initiatorUserId",
      headerName: "ORIGINATOR",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 165,
      renderCell: (params) => {
        if (params.value) {
          const initatedUser = users.find((user: User) => user.id === params.value);
          return initatedUser ? `${initatedUser.name.firstName} ${initatedUser.name.lastName}` : "";
        }
      },
    },
    {
      field: "settlementCorrelationId",
      headerName: "SETTLEMENT CORRELATION ID",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 110,
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        id: false,
        amountUSD: false,
        settlementCorrelationId: false,
        initiatorUserId: false,
        network: false,
      },
    },
  };
  const transactions: any = useAsyncValue();
  const data = { columns, initialState, rows: mapFauxIds(transactions) };

  useEffect(() => {
    setOrgDataCache((prevData) => ({
      ...prevData,
      transactions,
    }));
  }, [transactions]);

  return (
    <>
      <DataGridPremium
        {...data}
        slots={{
          pagination: GridPagination,
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
          sorting: {
            ...data.initialState?.columns,
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
          ...initialState,
        }}
        pageSizeOptions={[10, 20, 50]}
        pagination
        disableRowSelectionOnClick
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={`Copied ${copyItemLabel} to clipboard`}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      />
    </>
  );
}
