import {
  AllowlistAddress,
  AllowlistAddressRequest,
  Asset,
  AssetType,
  CreateAllowlistRequest,
  CreateAllowlistRequestTypeEnum,
  Network,
  PolicyActionDetails,
  PolicyInstanceRequestCategoryEnum,
  PriceInfo,
  UpdateWallet,
  Wallet,
  WalletTransaction,
  WalletType,
} from "../services/openAPI/client";
import { CryptoTickerEnum, DestinationTypeEnum, OwnerTypeEnum, WalletTemp } from "./CryptoIconsMap";
import { AllowListFormData } from "../pages/allowlist/AddAllowlist";
import { EditWalletFormData } from "../pages/wallet/WalletDetailsDialog";
import { formatDollarAmountUsd, formatUnixTime } from "@bakkt/bakkt-ui-components";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { EnvType } from "./customTypes";

export const shouldUseMockData = import.meta.env.VITE_USE_MOCK_DATA === "true";
//if token has not yet been replaced by CI/CD process, i.e. you are running locally, use vite env variable
export const API_URL =
  (window as any)["API_URL"] == "{{API_URL}}" ? import.meta.env.VITE_API_URL : (window as any)["API_URL"];
export const BLOCKCYPHER_URL =
  (window as any)["BLOCKCYPHER_URL"] == "{{BLOCKCYPHER_URL}}"
    ? import.meta.env.VITE_BLOCKCYPHER_URL
    : (window as any)["BLOCKCYPHER_URL"];
export const ETHERSCAN_URL =
  (window as any)["ETHERSCAN_URL"] == "{{ETHERSCAN_URL}}"
    ? import.meta.env.VITE_ETHERSCAN_URL
    : (window as any)["ETHERSCAN_URL"];
export const BCH_EXPLORER_URL =
  (window as any)["BCH_EXPLORER_URL"] == "{{BCH_EXPLORER_URL}}"
    ? import.meta.env.VITE_BCH_EXPLORER_URL
    : (window as any)["BCH_EXPLORER_URL"];
export const LTC_EXPLORER_URL =
  (window as any)["LTC_EXPLORER_URL"] == "{{LTC_EXPLORER_URL}}"
    ? import.meta.env.VITE_LTC_EXPLORER_URL
    : (window as any)["LTC_EXPLORER_URL"];
export const DOGE_EXPLORER_URL =
  (window as any)["DOGE_EXPLORER_URL"] == "{{DOGE_EXPLORER_URL}}"
    ? import.meta.env.VITE_DOGE_EXPLORER_URL
    : (window as any)["DOGE_EXPLORER_URL"];

export const USER_SESSION_KEY = "USERINFO";

export const FIREBASE_API_KEY =
  (window as any)["FIREBASE_API_KEY"] == "{{FIREBASE_API_KEY}}"
    ? import.meta.env.VITE_FIREBASE_API_KEY
    : (window as any)["FIREBASE_API_KEY"];
export const FIREBASE_AUTH_DOMAIN =
  (window as any)["FIREBASE_AUTH_DOMAIN"] == "{{FIREBASE_AUTH_DOMAIN}}"
    ? import.meta.env.VITE_FIREBASE_AUTH_DOMAIN
    : (window as any)["FIREBASE_AUTH_DOMAIN"];
export const FIREBASE_PROJECT_ID =
  (window as any)["FIREBASE_PROJECT_ID"] == "{{FIREBASE_PROJECT_ID}}"
    ? import.meta.env.VITE_FIREBASE_PROJECT_ID
    : (window as any)["FIREBASE_PROJECT_ID"];
export const FIREBASE_STORAGE_BUCKET =
  (window as any)["FIREBASE_STORAGE_BUCKET"] == "{{FIREBASE_STORAGE_BUCKET}}"
    ? import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
    : (window as any)["FIREBASE_STORAGE_BUCKET"];
export const FIREBASE_MESSAGING_SENDER_ID =
  (window as any)["FIREBASE_MESSAGING_SENDER_ID"] == "{{FIREBASE_MESSAGING_SENDER_ID}}"
    ? import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID
    : (window as any)["FIREBASE_MESSAGING_SENDER_ID"];
export const FIREBASE_APP_ID =
  (window as any)["FIREBASE_APP_ID"] == "{{FIREBASE_APP_ID}}"
    ? import.meta.env.VITE_FIREBASE_APP_ID
    : (window as any)["FIREBASE_APP_ID"];
export const FIREBASE_MEASUREMENT_ID =
  (window as any)["FIREBASE_MEASUREMENT_ID"] == "{{FIREBASE_MEASUREMENT_ID}}"
    ? import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
    : (window as any)["FIREBASE_MEASUREMENT_ID"];

export const MAX_WITHDRAW_LIMIT = 20000000;

export const environment: string =
  API_URL === "https://api.custody.bakkt.com" || API_URL === "https://api.custody.stg.bakkt.com"
    ? EnvType.Mainnet
    : EnvType.Testnet;

//TODO: Continue building out this enum if is not provided from backend
export const tickerToAssetEnum: Record<string, string> = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  LTC: "Litecoin",
  BCH: "Bitcoin Cash",
  DOGE: "Dogecoin",
  SHIB: "Shiba Inu",
  USDC: "USD Coin",
  ETC: "Ethereum Classic",
  CHL: "Chyle Token",
};

export const tickerToNetworkEnum: Record<string, string> = {
  BTC: "Bitcoin",
  ETH: "Ethereum",
  LTC: "Litecoin",
  BCH: "BitcoinCash",
  DOGE: "Dogecoin",
  SHIB: "Ethereum",
  USDC: "Ethereum",
  ETC: "EthereumClassic",
  CHL: "Ethereum",
};

export function convertPricingInfoArrayIntoMap(pricingInfoArray: PriceInfo[], assets: Asset[]) {
  const pricingInfoMap: Record<string, PriceInfo> = {};
  //If there is no PricingInfo, we are assuming pricing service is down, so will leave it as null for every asset.
  if (pricingInfoArray.length === 0) {
    return pricingInfoMap;
  }

  function getOneDollarPriceInfo(asset: Asset) {
    const oneDollarPriceInfo: PriceInfo = {
      assetSymbol: asset.symbol,
      assetTicker: asset.ticker,
      network: "mainnet",
      bidPrice: 0.0,
      bidSize: 0.0,
      bidPrice_24hAgo: 0.0,
      askPrice: 0.0,
      askSize: 0.0,
      askPrice_24hAgo: 0.0,
      timestamp: Date.now().toString(),
    };
    return oneDollarPriceInfo;
  }

  //Go through all assets and populate the pricingInfoMap , if you can't find priceInfo for an asset then stub one by calling getOneDollarPriceInfo.
  //We are assuming each asset has a unique ticker.
  assets.map((asset) => {
    pricingInfoMap[asset.ticker] =
      pricingInfoArray.find((priceInfo) => priceInfo.assetTicker === asset.ticker) || getOneDollarPriceInfo(asset);
  });
  return pricingInfoMap;
}

export interface BalanceSummary {
  total: number;
  warm: number;
  cold: number;
  warmQuantity: number;
  coldQuantity: number;
  omnibusTrading: number;
  segregatedCustody: number;
}

export interface OffExchangeBalanceSummary {
  totalBalance: number;
  totalAvailable: number;
  totalCredit: number;
  totalCollateral: number;
}

export function calculateBalanceTotalsFromWallets(
  wallets: Wallet[],
  pricingInfo: PriceInfo[],
  assets: Asset[],
): BalanceSummary {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo, assets);
  const uniqueTickers = [...new Set(wallets.map((wallet) => wallet.assetTicker || ""))];
  const walletMap: Record<string, Wallet[]> = {};
  uniqueTickers.map((uniqueTicker) => {
    walletMap[uniqueTicker] = wallets.filter((wallet) => wallet.assetTicker === uniqueTicker);
  });

  let totalBalance = 0;
  let totalWarmBalance = 0;
  let totalColdBalance = 0;
  let totalWarmQuantity = 0;
  let totalColdQuantity = 0;
  let totalOmnibusTradingBalance = 0;
  let totalSegregatedCustodyBalance = 0;
  for (const walletKey in walletMap) {
    const pricingInfoBidPrice = pricingInfoMap[walletKey]?.bidPrice || 0;
    const quantity = walletMap[walletKey].reduce((sum, cur) => {
      return sum + Number(cur.quantity);
    }, 0);
    const segregatedWalletTypes: string[] = [WalletType.Trust, WalletType.Custody, WalletType.Deposit];
    const omniWalletTypes: string[] = [WalletType.Trading, WalletType.Fee];

    const warmQuantity = walletMap[walletKey]
      .filter((wallet) => wallet.type === WalletType.Custody)
      .reduce((sum, cur) => {
        if (cur.temperature === "Hot" || cur.temperature === "Warm") {
          return sum + Number(cur.quantity);
        } else {
          return sum;
        }
      }, 0);
    const coldQuantity = walletMap[walletKey]
      .filter((wallet) => wallet.type === WalletType.Custody)
      .reduce((sum, cur) => {
        if (cur.temperature === "Cold") {
          return sum + Number(cur.quantity);
        } else {
          return sum;
        }
      }, 0);
    const omnibusQuantity = walletMap[walletKey]
      .filter((wallet) => omniWalletTypes.includes(wallet.type))
      .reduce((sum, cur) => {
        return sum + Number(cur.quantity);
      }, 0);
    const segregatedQuantity = walletMap[walletKey]
      .filter((wallet) => segregatedWalletTypes.includes(wallet.type))
      .reduce((sum, cur) => {
        return sum + Number(cur.quantity);
      }, 0);

    totalBalance += quantity * pricingInfoBidPrice;
    totalWarmBalance += warmQuantity * pricingInfoBidPrice;
    totalColdBalance += coldQuantity * pricingInfoBidPrice;
    totalWarmQuantity += warmQuantity;
    totalColdQuantity += coldQuantity;
    totalOmnibusTradingBalance += omnibusQuantity * pricingInfoBidPrice;
    totalSegregatedCustodyBalance += segregatedQuantity * pricingInfoBidPrice;
  }

  return {
    total: totalBalance,
    warm: totalWarmBalance,
    cold: totalColdBalance,
    warmQuantity: totalWarmQuantity,
    coldQuantity: totalColdQuantity,
    omnibusTrading: totalOmnibusTradingBalance,
    segregatedCustody: totalSegregatedCustodyBalance,
  };
}

export function checkWarmBalance(wallets: Wallet[], pricingInfo: PriceInfo[], assets: Asset[]): boolean {
  const balances = calculateBalanceTotalsFromWallets(wallets, pricingInfo, assets);
  if (balances.warmQuantity > 0) {
    return true;
  }
  return false;
}

export function checkWarmWallet(isWarmBalance: boolean, temperature: string) {
  if (!isWarmBalance) {
    return temperature === WalletTemp.Cold;
  }
  return true;
}

export function deriveWalletDetailsFromWalletsByAssetSymbol(wallets: Wallet[]) {
  const uniqueSymbols = [...new Set(wallets.map((wallet) => wallet.assetSymbol || ""))];
  const cryptoWalletMap: Record<string, Wallet[]> = {};
  uniqueSymbols.map((uniqueSymbol) => {
    cryptoWalletMap[uniqueSymbol] = wallets.filter((wallet) => wallet.assetSymbol === uniqueSymbol);
  });
  return cryptoWalletMap;
}

export function deriveWalletDetailsFromWalletsByAssetTicker(wallets: Wallet[]) {
  const uniqueTickers = [...new Set(wallets.map((wallet) => wallet.assetTicker || ""))];
  const cryptoWalletMap: Record<string, Wallet[]> = {};
  uniqueTickers.map((uniqueTicker) => {
    cryptoWalletMap[uniqueTicker] = wallets.filter((wallet) => wallet.assetTicker === uniqueTicker);
  });
  return cryptoWalletMap;
}

export interface WalletSummary {
  assetName: string;
  assetTicker: string;
  assetSymbol: string;
  percentChange: number;
  marketPrice: number;
  quantity: number;
  balance: number;
  actions: string;
  assetId?: number;
}

function getAssetFromTicker(assets: Asset[], cryptoWalletKey: string) {
  return assets.find((asset) => asset.symbol === cryptoWalletKey);
}

export function deriveWalletSummariesFromWallets(wallets: Wallet[], pricingInfo: PriceInfo[], assets: Asset[]) {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo, assets);
  const cryptoWalletMap = deriveWalletDetailsFromWalletsByAssetSymbol(wallets);
  const cryptoWalletSummary: WalletSummary[] = [];
  for (const cryptoWalletKey in cryptoWalletMap) {
    const quantity = cryptoWalletMap[cryptoWalletKey].reduce((sum, cur) => sum + Number(cur.quantity), 0);
    const cryptoAsset = getAssetFromTicker(assets, cryptoWalletKey);
    cryptoWalletSummary.push({
      assetName: cryptoAsset?.name || "",
      assetTicker: cryptoAsset?.ticker || "",
      assetSymbol: cryptoWalletKey,
      percentChange: 0,
      marketPrice: pricingInfoMap[cryptoAsset?.ticker || ""]?.bidPrice || 0,
      quantity: quantity,
      balance: (pricingInfoMap[cryptoAsset?.ticker || ""]?.bidPrice || 0) * quantity,
      actions: "New Wallet",
    });
  }
  return cryptoWalletSummary;
}

export function getWalletsByOrgId(orgId: number, wallets: Wallet[]) {
  return wallets.filter((wallet) => wallet.organizationId === orgId);
}

export function getNumberOfColdWalletsByAsset(
  assetSymbol: string | undefined,
  wallets: Wallet[],
  walletType: string,
): number {
  return wallets.filter(
    (wallet) =>
      wallet.assetSymbol === assetSymbol && wallet.temperature === WalletTemp.Cold && wallet.type === walletType,
  ).length;
}

export function getNumberOfWarmWalletsByAsset(
  assetSymbol: string | undefined,
  wallets: Wallet[],
  walletType: string,
): number {
  return wallets.filter(
    (wallet) =>
      wallet.assetSymbol === assetSymbol && wallet.temperature === WalletTemp.Warm && wallet.type === walletType,
  ).length;
}

export const getRemainingColdWallet = (
  assetSymbol: string | undefined,
  wallets: Wallet[],
  walletType: string,
  maxLimit: number,
) => {
  const current = getNumberOfColdWalletsByAsset(assetSymbol, wallets, walletType);
  if (current >= maxLimit) {
    return 0;
  } else {
    return maxLimit - current;
  }
};

export const getRemainingWarmWallet = (
  assetSymbol: string | undefined,
  wallets: Wallet[],
  walletType: string,
  maxLimit: number,
) => {
  const current = getNumberOfWarmWalletsByAsset(assetSymbol, wallets, walletType);
  console.log("current wallet count: " + current);
  if (current >= maxLimit) {
    return 0;
  } else {
    return maxLimit - current;
  }
};

export interface AvailableAndPendingBalanceSummary {
  totalBalanceCrypto: number;
  availableBalanceCrypto: number;
  pendingBalanceCrypto?: number;
  totalBalanceUsd: number;
  availableBalanceUsd: number;
  pendingBalanceUsd?: number;
}

export const getPriceByAssetQty = (assetTicker: string, qty: number, pricingInfo: PriceInfo[], assets: Asset[]) => {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo, assets);
  const bidPrice = pricingInfoMap[assetTicker]?.bidPrice || 0;

  return qty * bidPrice;
};

export const getAssetQtyByPrice = (assetTicker: string, amount: number, pricingInfo: PriceInfo[], assets: Asset[]) => {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo, assets);
  const bidPrice = pricingInfoMap[assetTicker].bidPrice || 0;
  return amount / bidPrice || 0;
};

export const getAvailableAndPendingBalances = (
  wallet: Wallet | undefined | null,
  pricingInfo: PriceInfo[],
  assets: Asset[],
): AvailableAndPendingBalanceSummary => {
  const assetTicker = wallet?.assetTicker || "";
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo, assets);
  const bidPrice = pricingInfoMap[assetTicker]?.bidPrice || 0;

  const total = wallet?.quantity || 0;
  const available = wallet?.availableBalance || 0;
  const pending = wallet?.pendingBalance || 0;

  return {
    totalBalanceCrypto: total,
    availableBalanceCrypto: available,
    pendingBalanceCrypto: pending,
    totalBalanceUsd: total * bidPrice,
    availableBalanceUsd: available * bidPrice,
    pendingBalanceUsd: pending * bidPrice,
  };
};

export function mapAllowListFormDataToAllowListAddressRequest(
  allowList: AllowListFormData,
  organizationId?: number,
): AllowlistAddressRequest {
  let createAllowListRequest: CreateAllowlistRequest = {
    address: allowList?.address,
    type: allowList?.type,
    organizationId: organizationId ? organizationId : allowList?.organizationId,
    accountId: allowList.accountId,
    assetSymbol: allowList?.assetSymbol,
    name: allowList?.name,
    isSelfHosted: allowList.isSelfHosted,
  };

  if (
    allowList.type === CreateAllowlistRequestTypeEnum.External &&
    allowList.destinationType === DestinationTypeEnum.Centralized
  ) {
    createAllowListRequest = {
      ...createAllowListRequest,
      financialInstitutionName: allowList?.financialInstitutionName,
    };
  } else if (
    allowList.type === CreateAllowlistRequestTypeEnum.External &&
    allowList.destinationType === DestinationTypeEnum.SelfHosted
  ) {
    createAllowListRequest = {
      ...createAllowListRequest,
      financialInstitutionName:
        allowList.ownerType === OwnerTypeEnum.Individual
          ? allowList?.individualFirstName + " " + allowList?.individualLastName
          : allowList?.companyName,
    };
  }
  return createAllowListRequest;
}

export function getAllowlistById(id: number, allowlist: AllowlistAddress[]): AllowlistAddress | undefined {
  return allowlist?.find((allowlist) => allowlist.id === id);
}

export function mapWalletFormDataToWalletRequest(editWalletFormData: EditWalletFormData): UpdateWallet {
  return {
    name: editWalletFormData.name,
    description: editWalletFormData.description,
  };
}

export function isTokenAsset(assetSymbol: string, assets: Asset[]): boolean {
  const asset = assets.find((a) => a.symbol === assetSymbol);
  if (asset?.type === AssetType.Token) {
    return true;
  }
  return false;
}

export function getFeeAssetForToken(assetSymbol: string, assets: Asset[]) {
  const symbol = assets.find((a) => a.symbol === assetSymbol);
  return symbol?.feeAsset;
}

export function getDefaultFeeWalletForTokenAsset(
  orgId: string | undefined,
  wallets: Wallet[],
  temp: string,
  assetSymbol: string,
  assets: Asset[],
): Wallet {
  const ticker = assets.find((a) => a.symbol === assetSymbol);
  return wallets
    .filter(
      (wallet) =>
        wallet.organizationId === Number(orgId) &&
        wallet.assetSymbol === ticker?.feeAsset &&
        wallet.temperature === temp,
    )
    .sort(compareWalletsById)[0];
}

export function compareWalletsById(wallet1: Wallet, wallet2: Wallet): number {
  const id1 = wallet1.walletId !== undefined ? wallet1.walletId : Number.MAX_SAFE_INTEGER;
  const id2 = wallet2.walletId !== undefined ? wallet2.walletId : Number.MAX_SAFE_INTEGER;
  return id1 - id2;
}

export function getGasBalanceForFeeWallet(wallet: Wallet | null, priceFeed: PriceInfo[], assets: Asset[]) {
  function getWalletBalances(): AvailableAndPendingBalanceSummary {
    return getAvailableAndPendingBalances(wallet, priceFeed, assets);
  }

  const balances = getWalletBalances();
  return balances.availableBalanceCrypto;
}

/**
 * Prepares and downloads a CSV file from an input array of strings, each element corresponds to a row in a CSV.
 * @param inputData: an array of rows and each row has "," as column separator
 * @param accountName
 */
export function generateAndDownloadCsv(inputData: string[], accountName: string) {
  const filename = accountName + " Balance Report " + formatUnixTime(new Date().getTime(), "long");
  try {
    const data = inputData.map((row) => row).join("\n");
    let csvString = "";

    Papa.parse(data, {
      header: false,
      complete: (result) => {
        const parsedData = result.data as string[][];

        //Convert Parsed data to CSV
        csvString = Papa.unparse(parsedData);
      },
      error: (error: Error) => {
        console.log("Error parsing CSV", error);
      },
    });

    if (csvString) {
      const blob = new Blob([csvString], { type: "text/csv" });
      saveAs(blob, filename || "data.csv");
    }
  } catch (error) {
    console.error("Error processing data:", error);
  }
}

export const mapFauxIds = (list: WalletTransaction[]) => {
  return list.map((item, i) => {
    return {
      ...(item as WalletTransaction),
      id: i,
    };
  });
};

export const getDecimalLength = (number: number) => {
  const decimalIndex = number.toString().indexOf(".");
  return decimalIndex >= 0 ? number.toString().length - decimalIndex - 1 : 0;
};

export const getRouteByRequestCategory = (policyItem: PolicyActionDetails): string => {
  const isTransactionPolicy =
    policyItem.policyInstanceRequestCategory === PolicyInstanceRequestCategoryEnum.Transaction;

  if (isTransactionPolicy && policyItem.policyInstanceRequestType?.includes("COLLATERAL")) {
    return policyItem.policyInstanceRequestType?.split("_")[1]?.toLowerCase() as string;
  }
  return isTransactionPolicy
    ? (policyItem.policyInstanceRequestType?.toLowerCase() as string)
    : (policyItem.policyInstanceRequestCategory?.toLowerCase() as string);
};

export const getScanLink = (symbol: string, walletAddress: string, assets: Asset[], networks: Network[]) => {
  const walletAddressAsset = assets.find((asset) => asset.symbol === symbol);
  const walletAddressNetwork = networks.find((network) => network.name === walletAddressAsset?.network);
  return `${walletAddressNetwork?.addressScannerUrl}${walletAddress}`;
};

export const isTrustWallet = (wallet: Wallet | null): boolean => {
  return WalletType.Custody === wallet?.type;
};

export const getSVGStringForTicker = (assets: Asset[], assetTicker: string) => {
  const svgForTicker = assets.find((asset) => asset.ticker === assetTicker)?.iconSVG || "";
  return svgForTicker;
};

//We expect Backend to be sending us assets based on the env we are in.
export const getAssetSymbolFromTicker = (ticker: string, assets: Asset[]): string => {
  return assets.find((asset) => asset.ticker === ticker)?.symbol || "";
};

export const getMinimunFeeAmount = (symbol: string, assets: Asset[]) => {
  return assets.find((asset) => asset.symbol === symbol)?.minimumFeeAmount || 0;
};

export const getMarketPriceForWallet = (wallet: Wallet, pricingInfoMap: Record<string, PriceInfo>) => {
  const formatOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
  };
  const marketPrice = pricingInfoMap[String(wallet.assetTicker)]?.bidPrice || 0;
  if (wallet.assetTicker === CryptoTickerEnum.SHIB) {
    return `$${marketPrice.toLocaleString(undefined, formatOptions)}`;
  }
  return formatDollarAmountUsd(marketPrice);
};

export function calculateOffExchangeTotalsFromWallets(
  wallets: Wallet[],
  pricingInfo: PriceInfo[],
  assets: Asset[],
): OffExchangeBalanceSummary {
  const pricingInfoMap = convertPricingInfoArrayIntoMap(pricingInfo, assets);
  const uniqueTickers = [...new Set(wallets.map((wallet) => wallet.assetTicker || ""))];
  const walletMap: Record<string, Wallet[]> = {};
  uniqueTickers.map((uniqueTicker) => {
    walletMap[uniqueTicker] = wallets.filter((wallet) => wallet.assetTicker === uniqueTicker);
  });

  let totalBalance = 0;
  let totalAvailable = 0;
  let totalCredit = 0;
  let totalCollateral = 0;
  for (const walletKey in walletMap) {
    const pricingInfoBidPrice = pricingInfoMap[walletKey]?.bidPrice || 0;
    const quantity = walletMap[walletKey].reduce((sum, cur) => {
      return sum + Number(cur.quantity);
    }, 0);

    const totalBalanceQuantity = walletMap[walletKey]
      .filter((wallet) => wallet.type === WalletType.OffExchange)
      .reduce((sum, cur) => {
        return sum + Number(cur.quantity || 0.0);
      }, 0);

    const totalAvailableQuantity = walletMap[walletKey]
      .filter((wallet) => wallet.type === WalletType.OffExchange)
      .reduce((sum, cur) => {
        return sum + Number(cur.availableBalance || 0.0);
      }, 0);

    const totalCreditQuantity = walletMap[walletKey]
      .filter((wallet) => wallet.type === WalletType.OffExchange)
      .reduce((sum, cur) => {
        return sum + Number(cur.creditBalance || 0.0);
      }, 0);
    const totalCollateralQuantity = walletMap[walletKey]
      .filter((wallet) => wallet.type === WalletType.OffExchange)
      .reduce((sum, cur) => {
        return sum + Number(cur.lockedBalance || 0.0);
      }, 0);

    totalBalance += totalBalanceQuantity * pricingInfoBidPrice;
    totalAvailable += totalAvailableQuantity * pricingInfoBidPrice;
    totalCredit += totalCreditQuantity * pricingInfoBidPrice;
    totalCollateral += totalCollateralQuantity * pricingInfoBidPrice;
  }

  return {
    totalBalance: totalBalance,
    totalAvailable: totalAvailable,
    totalCredit: totalCredit,
    totalCollateral: totalCollateral,
  };
}

export const formatCurrentDate = () => {
  const [weekday, month, day] = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  })
    .format(new Date())
    .split(" ");

  const getOrdinalSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const dayWithSuffix = day + getOrdinalSuffix(Number(day));

  return `${weekday} ${month} ${dayWithSuffix}`;
};

interface WalletWithAssetId extends Wallet {
  assetId?: number;
}

export function sortWalletsByAssetId(wallets: WalletWithAssetId[], assets: Asset[]) {
  for (const wallet of wallets) {
    const asset = assets.find((a) => a.ticker === wallet.assetTicker);
    if (asset) wallet.assetId = asset.id;
  }
  return wallets.sort((a: WalletWithAssetId, b: WalletWithAssetId) => (Number(a.assetId) > Number(b.assetId) ? 1 : -1));
}

export function sortWalletSummaryByAssetId(wallets: WalletSummary[], assets: Asset[]) {
  const walletSummaries: [string, WalletSummary][] = [
    ...new Map(wallets.map((wallet) => [wallet.assetSymbol, wallet])),
  ];
  const cryptoWallets: WalletSummary[] = [];
  const walletSummariesGroupedByAssetTicker: any = {};
  for (const wallet of walletSummaries) {
    if (walletSummariesGroupedByAssetTicker[wallet[1].assetTicker]) {
      walletSummariesGroupedByAssetTicker[wallet[1].assetTicker].quantity += wallet[1].quantity;
      walletSummariesGroupedByAssetTicker[wallet[1].assetTicker].balance += wallet[1].balance;
    } else {
      walletSummariesGroupedByAssetTicker[wallet[1].assetTicker] = { ...wallet[1] };
      const asset = assets.find((a) => a.symbol === wallet[1].assetSymbol);
      if (asset) {
        walletSummariesGroupedByAssetTicker[wallet[1].assetTicker].assetId = asset.id;
      }
      cryptoWallets.push(walletSummariesGroupedByAssetTicker[wallet[1].assetTicker]);
    }
  }
  return cryptoWallets.sort((a: WalletSummary, b: WalletSummary) => (Number(a.assetId) > Number(b.assetId) ? 1 : -1));
}

export function formatCorrelationId(correlationId: string) {
  const correlationIdFirstFour = correlationId.substring(0, 4);
  const correlationIdLastFour = correlationId.substring(32, 36);
  return `${correlationIdFirstFour}...${correlationIdLastFour}`;
}

/**
 * Utility function to retrieve the base asset associated with a given asset.
 * For instance, if the input asset is an ERC-20 token like USDC, this function
 * would return the corresponding base asset, which is Ethereum (ETH).
 * */
export function getBaseAssetFromAsset(asset: Asset | undefined, assets: Asset[], netwoks: Network[]) {
  if (!asset) {
    return undefined;
  }
  const assetNetwork = netwoks.find((net) => net.name === asset?.network);
  const baseAsset = assets.find((asset) => asset.network === assetNetwork?.name && asset.type === AssetType.Base);
  return baseAsset;
}
