import { RootContextType, useRootContext } from "../../RootLayout";
import {
  defer,
  LoaderFunctionArgs,
  useFetcher,
  useLoaderData,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  AllowlistAddress,
  AllowlistAddressStatusEnum,
  Asset,
  CreateTransactionRequest,
  CreateTransactionRequestTypeEnum,
  PriceInfo,
  Wallet,
  WalletType,
} from "../../services/openAPI/client";
import {
  checkWarmBalance,
  getAssetQtyByPrice,
  getAvailableAndPendingBalances,
  getGasBalanceForFeeWallet,
  isTokenAsset,
  shouldUseMockData,
} from "../../utils/dataUtils";
import { CryptoTickerEnum, WalletTemp } from "../../utils/CryptoIconsMap";
import { allow, fetchMockDataPromiseWithDelay } from "../../services/mockData";
import { AllowListService, WalletTransactionService } from "../../services/serviceLoader";
import { destinationType } from "../../utils/customTypes";
import { formatActionErrorResponse, formatActionSuccessResponse } from "../../utils/responseHandlingUtils.ts";
import WithdrawModal from "./withdraw/WithdrawModal.tsx";
import { WalletWithdrawContext, withdrawContext, WithdrawMinimal } from "./withdraw/WithdrawContext.tsx";
import { SelectChangeEvent } from "@mui/material";

export default function WalletWithdraw() {
  const { policyAllowlistListPromise } = useLoaderData() as {
    policyAllowlistListPromise: Promise<AllowlistAddress[]>;
  };
  const { orgDataCache, selectedOrg, userInfo, priceFeed, setShouldRefreshPolicyItems, addAlert, assets } =
    useRootContext() as RootContextType;
  const wallets = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets];
  const { state } = useLocation();
  const assetSymbol = state?.assetSymbol;
  const { walletId } = useParams();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const isWarmBalance = checkWarmBalance(wallets as Wallet[], priceFeed as PriceInfo[], assets);
  const BTCAsset = assets.find((asset) => asset.ticker === CryptoTickerEnum.BTC);

  const getSelectedWallet = (id: number | null): Wallet | undefined => {
    return wallets.find((wallet: Wallet) => wallet.walletId == id);
  };

  const [selectedWalletId, setSelectedWalletId] = useState<number | null>(
    walletId ? (parseInt(walletId as string) as number) : null,
  );
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(
    getSelectedWallet(selectedWalletId as number) as Wallet,
  );
  const [selectedWalletType, setSelectedWalletType] = useState<string | undefined>(getDefaultWalletType());
  const [selectedAsset, setSelectedAsset] = useState<string>(
    selectedWallet?.assetSymbol || assetSymbol || BTCAsset?.symbol,
  );
  const [currencyView, setCurrencyView] = useState<string>("CRYPTO");
  const [withdrawStep, setWithdrawStep] = useState<number>(1);
  const [allowListedAddresses, setAllowListedAddresses] = useState<AllowlistAddress[]>([]);

  const [selectedAddress, setSelectedAddress] = useState<AllowlistAddress | null>();
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [destinationAddressType, setDestinationAddressType] = useState<string>("");
  const [selectDestinationType, setSelectDestinationType] = useState("");
  const [selectedDestinationWallet, setSelectedDestinationWallet] = useState<Wallet | null>();

  //For Token Assets
  const [isToken, setIsToken] = useState<boolean>(false);
  const [feeWallet, setFeeWallet] = useState<Wallet | null>(null);
  const [gasBalance, setGasBalance] = useState<number>(0);

  const [balances, setBalances] = useState({
    availableBalanceCrypto: 0,
    availableBalanceUsd: 0,
  });

  const withdrawFormData: Partial<WithdrawMinimal> = {
    assetTicker: selectedWallet?.assetTicker || "",
    assetSymbol: selectedAsset,
    fromWalletId: selectedWalletId || null,
    fromWalletAddress: selectedWallet?.address || "",
    toWalletId: "" || null,
    toWalletAddress: "",
    allowListId: null,
    allowListName: null,
    destinationType: "",
    quantity: "",
    originatorId: userInfo.userId,
    accountId: userInfo.accountId,
    organizationId: selectedOrg.id,
  };

  const [withdraw, setWithdraw] = useState<Partial<WithdrawMinimal>>(withdrawFormData);

  function getDefaultWalletType() {
    const selectedSourceWallet = getSelectedWallet(parseInt(walletId || "")) as Wallet;
    return selectedSourceWallet ? selectedSourceWallet.type.valueOf() : undefined;
  }

  function updateTokenAssetChanges(updatedSymbol?: string, updatedWalletId?: number | undefined) {
    if (updatedSymbol) {
      setIsToken(isTokenAsset(updatedSymbol, assets));
    }
    const fromWallet: Wallet | null = wallets.find((wallet) => wallet.walletId === updatedWalletId) || null;
    const feeWallet = wallets.find((w) => fromWallet?.feeWalletId === w.walletId) || null;

    const gasBalance = getGasBalanceForFeeWallet(feeWallet, priceFeed, assets) || 0;
    setGasBalance(gasBalance);
    setFeeWallet(feeWallet);
  }

  const resetAllValues = () => {
    // wipe selected addresses
    setSelectedAddress(null);
    setSelectedAddressId(null);
    // reset selected wallet to param
    setSelectedWalletId(null);
    setSelectedWallet(null);
    setSelectDestinationType("");
    setSelectedDestinationWallet(null);
    // reset the formData
    setWithdraw(withdrawFormData);
  };

  const updateField = (fieldName: string, value: string | number | boolean) => {
    setWithdraw((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleWalletType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const walletType = event.target.value as WalletType;
    setSelectedWalletType(walletType as WalletType);
    setSelectedWalletId(null);
    setSelectDestinationType("");
    setSelectedDestinationWallet(null);
    setSelectedWallet(null);

    setDestinationAddressType("");
    setSelectedAddress(null);
    setSelectedAddressId(null);
  };

  const handleChangeAsset = (_: React.SyntheticEvent, newAsset: Asset | null) => {
    if (newAsset) {
      // set the selected asset
      setSelectedAsset(newAsset.symbol);
      // apply filter to address list
      setAddressList(filterAddressesByAsset(newAsset.symbol));
      // reset any lower form values
      resetAllValues();
      //set Token Asset related variables
      updateTokenAssetChanges(newAsset.symbol, undefined);
    }
  };

  const handleChangeWallet = async (event: SelectChangeEvent) => {
    // on wallet select, set up full allowlist & filtered address lists
    await policyAllowlistListPromise.then((response) => {
      setAllowListedAddresses(response);
      setAddressList(response);
    });
    const newSelectedWallet = getSelectedWallet(parseInt(event.target.value)) as Wallet;
    // update formData
    updateField("fromWalletId", event.target.value);
    updateField("fromWalletAddress", newSelectedWallet!.address as string);
    updateField("assetTicker", newSelectedWallet.assetTicker as string);
    updateField("assetSymbol", newSelectedWallet.assetSymbol as string);
    // set values
    setSelectedWallet(newSelectedWallet as Wallet);
    setSelectedWalletId(newSelectedWallet.walletId as number);
    setSelectedAsset(newSelectedWallet.assetSymbol as string);
    // changing wallet should not reset lower form values

    //set Token Asset related values
    updateTokenAssetChanges(undefined, newSelectedWallet.walletId);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
    const name = event.target.name;
    let value = event.target.value;

    if (currencyView === "CRYPTO") {
      if (value.startsWith(".")) {
        value = `0${value}`;
      } else if (value.startsWith("0") && !value.startsWith("0.") && value.length > 1) {
        value = `0.${value.substring(1)}`;
      }
    }

    const decimalRegex =
      currencyView === "CRYPTO" && selectedAsset === CryptoTickerEnum.BTC ? /^\d*(\.\d{0,8})?$/ : /^\d*(\.\d{0,18})?$/;

    if (decimalRegex.test(value)) {
      updateField(name, value);
    }
  };

  const removeWarmWalletAddress = (addresses: AllowlistAddress[]) => {
    return addresses.filter((address) => {
      const wallet = wallets.find((wallet) => (wallet.address as string) === address.address);

      return !wallet || wallet.temperature == WalletTemp.Cold;
    });
  };

  const filterAddressesByAsset = (asset?: string, addressListCheck?: AllowlistAddress[]) => {
    const filterBy = asset ? asset : selectedAsset;
    let addresses = addressListCheck ? addressListCheck : allowListedAddresses;
    if (destinationAddressType === destinationType.External) {
      addresses = addresses.filter((address) => address.type == destinationType.External);
    } else if (destinationAddressType === destinationType.Internal) {
      //Filter only allowlists whose type is internal and not omnibus(deposit address)
      addresses = addresses.filter((address) => address.type === destinationType.Internal && !address.tradingAddress);
    } else if (destinationAddressType === destinationType.Trading) {
      addresses = addresses.filter((address) => address.type === destinationType.Internal && address.tradingAddress);
    }

    if (!isWarmBalance) {
      addresses = removeWarmWalletAddress(addresses);
    }

    return addresses
      .filter((address) => address.assetSymbol === filterBy && address.address !== selectedWallet?.address)
      .sort((a: AllowlistAddress, b: AllowlistAddress) =>
        (a.name ? a.name : "") < (b.name ? b.name : "") ? -1 : 1,
      ) as AllowlistAddress[];
  };

  const [addressList, setAddressList] = useState<AllowlistAddress[]>(filterAddressesByAsset());

  const handleCurrencyToggle = (event: React.MouseEvent<HTMLElement>, currency: string | null) => {
    const value = event.target;
    setCurrencyView(currency as string);
  };

  const handleReviewWithdraw = () => {
    // before we progress, check if amount needs converted from
    // input USD value to crypto value
    let amount = Number(withdraw.quantity);
    if (currencyView !== "CRYPTO") {
      // get asset QTY from dollar value & assetTicker
      amount = getAssetQtyByPrice(selectedWallet?.assetTicker as string, amount, priceFeed, assets);
      // convert back to string
      updateField("quantity", amount.toString());
    }
    setWithdrawStep(withdrawStep + 1);
  };

  const handleCreateWithdraw = () => {
    const createWithdraw: CreateTransactionRequest = {
      type: CreateTransactionRequestTypeEnum.Withdraw,
      sourceWalletId: Number(withdraw.fromWalletId),
      allowlistId: Number(withdraw.allowListId),
      quantity: Number(withdraw.quantity),
      destinationWalletId: Number(withdraw.toWalletId),
    };
    fetcher.submit(JSON.stringify(createWithdraw), {
      method: "post",
      encType: "application/json",
    });
  };

  useEffect(() => {
    (async () => {
      await policyAllowlistListPromise.then((response) => {
        setAllowListedAddresses(response);
        setAddressList(response);
      });
    })();
  }, []);

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "success",
          messageHeader: "Withdraw transaction has been initiated.",
        });
      } else {
        setShouldRefreshPolicyItems(false);
        addAlert({
          severity: "error",
          messageHeader: "Error while initiating withdraw transaction.",
          message: `${response.message}: ${response.data.message}`,
        });
      }
      navigate(`/${selectedOrg.id}`);
    }
  }, [fetcher.data]);

  useEffect(() => {
    if (selectedWallet) {
      setBalances(getAvailableAndPendingBalances(selectedWallet, priceFeed, assets));
      setSelectedWalletType(selectedWallet.type as WalletType);
      //set Token Asset related values
      updateTokenAssetChanges(selectedWallet?.assetSymbol || "", selectedWallet.walletId);
    }
  }, [selectedWallet]);

  const withdrawCtxValue: WalletWithdrawContext = {
    selectedWallet,
    selectedWalletId,
    selectedWalletType,
    currencyView,
    withdraw,
    selectedAsset,
    handleWalletType,
    handleChangeAsset,
    handleChangeWallet,
    handleCurrencyToggle,
    isWarmBalance,
    updateField,
    filterAddressesByAsset,
    addressList,
    allowListedAddresses,
    withdrawStep,
    handleReviewWithdraw,
    handleCreateWithdraw,
    gasBalance,
    feeWallet,
    isToken,
    destinationAddressType,
    setDestinationAddressType,
    policyAllowlistListPromise,
    handleInputChange,
    balances,
    selectedAddress,
    selectedAddressId,
    setSelectedAddress,
    setSelectedAddressId,
    setSelectDestinationType,
  };

  return (
    <withdrawContext.Provider value={withdrawCtxValue}>
      <WithdrawModal />
    </withdrawContext.Provider>
  );
}

export async function loader({ params }: LoaderFunctionArgs) {
  try {
    const orgId = Number(params.organizationId);
    const walletId = Number(params.walletId);

    const policyAllowlistListPromise = shouldUseMockData
      ? fetchMockDataPromiseWithDelay(allow, 500)
      : AllowListService.getAllowlistAddresses(orgId, undefined, AllowlistAddressStatusEnum.Approved);

    return defer({
      policyAllowlistListPromise: policyAllowlistListPromise,
    });
  } catch (e) {
    console.error("Error fetching transaction data: ", e);
    return {};
  }
}

export async function action({ request }: { request: Request }) {
  try {
    const createWalletTransaction = (await request.json()) as CreateTransactionRequest;
    const createTransactionResponse = await WalletTransactionService.createTransaction(createWalletTransaction);
    return formatActionSuccessResponse(createTransactionResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
