import { TextField, Typography, color } from "@bakkt/bakkt-ui-components";
import { Chip, MenuItem, SvgIcon, Unstable_Grid2 as Grid, Autocomplete, Box } from "@mui/material";
import { AssetSVGIcon } from "../customSVG/AssetSVGIcon";
import { CryptoTickerEnum } from "../../utils/CryptoIconsMap";
import { Asset, Wallet } from "../../services/openAPI/client";
import { WalletFormData } from "../../pages/wallet/AddWallet";

interface AssetDropDownProps {
  assets: Asset[];
  handleAssetChange: (_: React.SyntheticEvent<Element, Event>, newWalletAsset: Asset | null) => void;
  wallet?: Wallet | WalletFormData | null;
  selectedAsset?: string;
}

const AssetDropDown = ({ assets, handleAssetChange, wallet, selectedAsset }: AssetDropDownProps) => {
  const assetSymbol = wallet ? wallet.assetSymbol : selectedAsset;
  return (
    <Autocomplete
      value={assets.find((asset: Asset) => asset.symbol === assetSymbol) || null}
      onChange={handleAssetChange}
      getOptionLabel={(options: Asset) => `${options.name} on ${options.network}`}
      options={assets.sort((assetA: Asset, assetB: Asset) => (assetA.name > assetB.name ? 1 : -1))}
      isOptionEqualToValue={(option, value) => option.symbol === value.symbol}
      renderOption={(props, option) => (
        <Box
          {...props}
          component={"li"}
          sx={{
            p: 0,
            my: 0,
            "&:hover": { backgroundColor: `${color.provocativePlum[50]} !important` },
            "&[aria-selected='true']": {
              backgroundColor: `${color.provocativePlum[50]} !important`,
            },
            "&:active": {
              backgroundColor: `${color.provocativePlum[50]} !important`,
            },
            "&.Mui-focused": {
              backgroundColor: `${color.provocativePlum[50]} !important`,
            },
            "&[data-focus='true']": {
              backgroundColor: `${color.provocativePlum[50]} !important`,
            },
          }}
        >
          <MenuItem value={option.symbol} disabled={option.ticker === CryptoTickerEnum.ETC}>
            <Grid container alignItems={"center"} sx={{ p: 1, pb: 0 }}>
              <SvgIcon
                component={() =>
                  AssetSVGIcon({
                    svgString: option.iconSVG,
                    title: option.ticker,
                    sx: { width: 32, height: 32, pr: 1 },
                  })
                }
                inheritViewBox
              />
              <Grid>
                <Typography sx={{ fontWeight: 600, mr: 2 }}>{option.name}</Typography>
                <Typography variant={"body2"} sx={{ mr: 2 }}>
                  {option.ticker} on {option.network}
                </Typography>
              </Grid>
              {option.ticker === CryptoTickerEnum.ETC && <Chip key={option.ticker} label={"Contact Support"} />}
            </Grid>
          </MenuItem>
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Select Asset" variant="standard" required />}
    />
  );
};

export default AssetDropDown;
