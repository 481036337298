import { useState, useEffect } from "react";
import { AllowlistAddress, Asset, EstimateFee, Wallet } from "../services/openAPI/client";
import { WalletTransactionService } from "../services/serviceLoader";
import { CollateralMinimal } from "../pages/wallet/manageCollateral/CollateralContext";

import { getFeeAssetForToken, isTokenAsset } from "../utils/dataUtils";
import { WithdrawMinimal } from "../pages/wallet/withdraw/WithdrawContext";

const useGetNetworkFeeEstimation = (
  transactionRequest: Partial<WithdrawMinimal> | Partial<CollateralMinimal>,
  selectedWallet: Wallet,
  selectedDestinationAddress: AllowlistAddress | Wallet | undefined,
  wallets: Wallet[],
  assets: Asset[],
) => {
  const [networkFeeEstimation, setNetworkFeeEstimation] = useState<EstimateFee>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isToken = isTokenAsset(selectedWallet?.assetSymbol as string, assets);

  const networkFeeTicker = isToken
    ? getFeeAssetForToken(transactionRequest.assetSymbol as string, assets)
    : (transactionRequest.assetTicker as string);
  const feeWallet = isToken && (wallets?.find((wallet) => wallet.walletId === selectedWallet.feeWalletId) as Wallet);

  const getNetworkFeeEstimation = async () => {
    const fee = await WalletTransactionService.getEstimateFee(
      Number(transactionRequest?.quantity),
      Number(selectedWallet.walletId),
      String(selectedDestinationAddress?.address),
    );
    setNetworkFeeEstimation(fee as EstimateFee);
    setIsLoading(false);
  };

  useEffect(() => {
    getNetworkFeeEstimation();
  }, []);

  return {
    isLoading,
    isToken,
    networkFeeEstimation,
    networkFeeTicker,
    feeWallet,
  };
};

export default useGetNetworkFeeEstimation;
