import Paper from "@mui/material/Paper";
import { Container, Grid, Stack, styled } from "@mui/material";
import { offExgAggregateHeader, offExgTypography, tableHeader } from "./styles.tsx";
import { formatDollarAmountUsd, Typography } from "@bakkt/bakkt-ui-components";
import { OffExchangeBalanceSummary } from "../../utils/dataUtils.ts";

interface OffExgAggregateProps {
  allBalances: OffExchangeBalanceSummary;
}
export default function OffExchangeAggregates(balances: OffExgAggregateProps) {
  const totalValue = 1200000.0;
  const balanceSummary = balances.allBalances;

  return (
    <Paper>
      <Grid container spacing={2} xs={12}>
        <Container data-testid="offExchange-totals" sx={offExgAggregateHeader}>
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={6}>
            <Typography sx={offExgTypography}></Typography>
            <Typography sx={offExgTypography}>
              TOTAL VALUE: {formatDollarAmountUsd(balanceSummary.totalBalance)}
            </Typography>
            <Typography sx={offExgTypography}>
              TOTAL TRADABLE: {formatDollarAmountUsd(balanceSummary.totalAvailable)}
            </Typography>
            <Typography sx={offExgTypography}>
              TOTAL CREDIT: {formatDollarAmountUsd(balanceSummary.totalCredit)}
            </Typography>
            <Typography sx={offExgTypography}>
              TOTAL COLLATERAL: {formatDollarAmountUsd(balanceSummary.totalCollateral)}
            </Typography>
          </Stack>
        </Container>
      </Grid>
    </Paper>
  );
}
