import { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate, useParams } from "react-router-dom";
import ManageCollateralModal from "./ManageCollateralModal";
import { SelectChangeEvent } from "@mui/material";
import { useRootContext, RootContextType } from "../../../RootLayout";
import {
  Asset,
  CreateTransactionRequest,
  CreateTransactionRequestTypeEnum,
  Wallet,
  WalletTransactionTypeEnum,
} from "../../../services/openAPI/client";
import { CryptoTickerEnum } from "../../../utils/CryptoIconsMap";
import {
  getAssetQtyByPrice,
  getAvailableAndPendingBalances,
  getGasBalanceForFeeWallet,
  isTokenAsset,
} from "../../../utils/dataUtils";
import { CollateralMinimal, ManageCollateralContext } from "./CollateralContext";
import { WalletTransactionService } from "../../../services/serviceLoader";
import { formatActionSuccessResponse, formatActionErrorResponse } from "../../../utils/responseHandlingUtils";

const ManageCollateral = () => {
  const { orgDataCache, selectedOrg, userInfo, priceFeed, assets, addAlert, setShouldRefreshPolicyItems } =
    useRootContext() as RootContextType;
  const wallets = [...orgDataCache.wallets, ...orgDataCache.offExchangeWallets];
  const fetcher = useFetcher();
  const { walletId: offExchangeWalletId } = useParams();
  const { state } = useLocation();
  const assetSymbol = state?.symbol;
  const BTCAsset = assets.find((asset) => asset.ticker === CryptoTickerEnum.BTC);
  const navigate = useNavigate();

  const getSelectedWallet = (id: number | null): Wallet | undefined => {
    return wallets.find((wallet: Wallet) => wallet.walletId == id);
  };

  const [selectedWalletId, setSelectedWalletId] = useState<number | null>();
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(
    getSelectedWallet(selectedWalletId as number) as Wallet,
  );
  const offExchangeWallet = getSelectedWallet(parseInt(offExchangeWalletId as string) as number);

  const [selectedAsset, setSelectedAsset] = useState<string>(
    selectedWallet?.assetSymbol || assetSymbol || BTCAsset?.symbol,
  );
  const [currencyView, setCurrencyView] = useState<string>("CRYPTO");
  const [collateralStep, setCollateralStep] = useState<number>(1);
  const [collateralAction, setCollateralAction] = useState<string>("");

  //For Token Assets
  const [isToken, setIsToken] = useState<boolean>(false);
  const [feeWallet, setFeeWallet] = useState<Wallet | null>(null);
  const [gasBalance, setGasBalance] = useState<number>(0);

  const collateralFormData: Partial<CollateralMinimal> = {
    assetTicker: selectedWallet?.assetTicker || "",
    assetSymbol: selectedAsset,
    fromWalletId: selectedWalletId || null,
    fromWalletAddress: selectedWallet?.address || "",
    offExchangeWalletId: offExchangeWallet?.walletId,
    toWalletId: "" || null,
    toWalletAddress: "",
    exchange: "Deribit",
    action: "",
    quantity: "",
    originatorId: userInfo.userId,
    accountId: userInfo.accountId,
    organizationId: selectedOrg.id,
  };

  const [collateral, setCollateral] = useState<Partial<CollateralMinimal>>(collateralFormData);
  const [balances, setBalances] = useState({
    availableBalanceCrypto: 0,
    availableBalanceUsd: 0,
  });

  const updateField = (fieldName: string, value: string | number | boolean) => {
    setCollateral((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const updateTokenAssetChanges = (updatedSymbol?: string, updatedWalletId?: number | undefined) => {
    if (updatedSymbol) {
      setIsToken(isTokenAsset(updatedSymbol, assets));
    }
    const fromWallet: Wallet | null = wallets.find((wallet) => wallet.walletId === updatedWalletId) || null;
    const feeWallet = wallets.find((w) => fromWallet?.feeWalletId === w.walletId) || null;

    const gasBalance = getGasBalanceForFeeWallet(feeWallet, priceFeed, assets) || 0;
    setGasBalance(gasBalance);
    setFeeWallet(feeWallet);
  };

  const handleChangeAsset = (_: React.SyntheticEvent, newAsset: Asset | null) => {
    if (newAsset) {
      // set the selected asset
      setSelectedAsset(newAsset.symbol);
      setSelectedWallet(null);
      setSelectedWalletId(null);
      updateField("quantity", "");
      //set Token Asset related variables
      updateTokenAssetChanges(newAsset.symbol, undefined);
    }
  };

  const handleChangeWallet = (event: SelectChangeEvent) => {
    const newSelectedWallet = getSelectedWallet(parseInt(event.target.value)) as Wallet;
    // update formData
    if (collateralAction === WalletTransactionTypeEnum.AddCollateralWithdraw) {
      updateField("fromWalletId", event.target.value);
      updateField("fromWalletAddress", newSelectedWallet!.address as string);
    } else if (collateralAction === WalletTransactionTypeEnum.RemoveCollateralWithdraw) {
      updateField("toWalletId", event.target.value);
      updateField("toWalletAddress", newSelectedWallet!.address as string);
    }
    updateField("assetTicker", newSelectedWallet.assetTicker as string);
    updateField("assetSymbol", newSelectedWallet.assetSymbol as string);
    // set values
    setSelectedWallet(newSelectedWallet as Wallet);
    setSelectedWalletId(newSelectedWallet.walletId as number);
    setSelectedAsset(newSelectedWallet.assetSymbol as string);
    //set Token Asset related values
    updateTokenAssetChanges(undefined, newSelectedWallet.walletId);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
    const name = event.target.name;
    let value = event.target.value;

    if (currencyView === "CRYPTO") {
      if (value.startsWith(".")) {
        value = `0${value}`;
      } else if (value.startsWith("0") && !value.startsWith("0.") && value.length > 1) {
        value = `0.${value.substring(1)}`;
      }
    }

    const decimalRegex =
      currencyView === "CRYPTO" && selectedAsset === CryptoTickerEnum.BTC ? /^\d*(\.\d{0,8})?$/ : /^\d*(\.\d{0,18})?$/;

    if (decimalRegex.test(value)) {
      updateField(name, value);
    }
  };

  const handleCurrencyToggle = (event: React.MouseEvent<HTMLElement>, currency: string | null) => {
    const value = event.target;
    setCurrencyView(currency as string);
  };

  const handleCollateralAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    // set add or remove colllateral
    const action = event.target.value;
    setCollateralAction(event.target.value);
    updateField("action", action);
  };

  const handleReviewCollateralRequest = () => {
    // before we progress, check if amount needs converted from
    // input USD value to crypto value
    let amount = Number(collateral.quantity);
    if (currencyView !== "CRYPTO") {
      // get asset QTY from dollar value & assetTicker
      amount = getAssetQtyByPrice(selectedWallet?.assetTicker as string, amount, priceFeed, assets);
      // convert back to string
      updateField("quantity", amount.toString());
    }
    setCollateralStep(2);
  };

  const getCollateralTransactionRequestDetails = () => {
    return collateral.action === WalletTransactionTypeEnum.AddCollateralWithdraw
      ? {
          type: CreateTransactionRequestTypeEnum.AddCollateralWithdraw,
          sourceWalletId: Number(collateral.fromWalletId),
          destinationWalletId: Number(collateral.offExchangeWalletId),
        }
      : {
          type: CreateTransactionRequestTypeEnum.RemoveCollateralWithdraw,
          sourceWalletId: Number(collateral.offExchangeWalletId),
          destinationWalletId: Number(collateral.toWalletId),
        };
  };

  const handleCreateCollateralRequest = () => {
    const collateralTransactionRequestDetails = getCollateralTransactionRequestDetails();
    const createCollateralRequest: CreateTransactionRequest = {
      quantity: Number(collateral.quantity),
      ...collateralTransactionRequestDetails,
    };
    fetcher.submit(JSON.stringify(createCollateralRequest), {
      method: "post",
      encType: "application/json",
    });
  };

  useEffect(() => {
    const response = fetcher.data;
    if (response) {
      if (response.success) {
        setShouldRefreshPolicyItems(true);
        addAlert({
          severity: "success",
          messageHeader: "Collateral Request has been initiated.",
        });
      } else {
        setShouldRefreshPolicyItems(false);
        addAlert({
          severity: "error",
          messageHeader: "Error while initiating collateral request.",
          message: `${response.message}: ${response.data.message}`,
        });
      }
      navigate(`/${selectedOrg.id}`);
    }
  }, [fetcher.data]);

  useEffect(() => {
    if (selectedWallet) {
      setBalances(getAvailableAndPendingBalances(selectedWallet, priceFeed, assets));
      //set Token Asset related values
      updateTokenAssetChanges(selectedWallet?.assetSymbol || "", selectedWallet.walletId);
    }
  }, [selectedWallet]);

  return (
    <ManageCollateralContext.Provider
      value={{
        selectedWallet,
        selectedWalletId,
        selectedAsset,
        collateral,
        collateralAction,
        collateralStep,
        currencyView,
        offExchangeWallet,
        handleChangeWallet,
        handleChangeAsset,
        handleCollateralAction,
        handleCurrencyToggle,
        handleInputChange,
        handleReviewCollateralRequest,
        handleCreateCollateralRequest,
      }}
    >
      <ManageCollateralModal isToken={isToken} gasBalance={gasBalance} feeWallet={feeWallet} />
    </ManageCollateralContext.Provider>
  );
};

export default ManageCollateral;

export async function action({ request }: { request: Request }) {
  try {
    const createWalletTransaction = (await request.json()) as CreateTransactionRequest;
    const createTransactionResponse = await WalletTransactionService.createTransaction(createWalletTransaction);
    return formatActionSuccessResponse(createTransactionResponse);
  } catch (error) {
    return formatActionErrorResponse(error);
  }
}
