import { formatDollarAmountUsd } from "@bakkt/bakkt-ui-components";
import { getAssetQtyByPrice, getPriceByAssetQty } from "../../../utils/dataUtils";
import { Typography } from "@bakkt/bakkt-ui-components";
import { RootContextType, useRootContext } from "../../../RootLayout";
import { useTheme } from "@mui/material/styles";

interface walletAvailableProps {
  selectedAsset: string;
  amount: string;
  ticker: string;
  view: string;
  disabled?: boolean;
}

export const WalletBalanceAvailableMinimal = (props: walletAvailableProps) => {
  const { priceFeed, assets } = useRootContext() as RootContextType;
  const theme = useTheme();
  const { amount, ticker, view, disabled, selectedAsset } = {
    amount: props.amount,
    ticker: props.ticker,
    view: props.view,
    disabled: props.disabled,
    selectedAsset: props.selectedAsset,
  };

  const walletAsset = assets.find((asset) => asset.symbol === selectedAsset);
  return (
    <>
      {view === "CRYPTO" ? (
        <Typography variant="subtitle2" sx={{ color: disabled ? theme.palette.text.disabled : "" }}>
          USD Value: {formatDollarAmountUsd(getPriceByAssetQty(ticker, Number(amount), priceFeed, assets) || 0)}
        </Typography>
      ) : (
        <Typography variant="subtitle2" sx={{ color: disabled ? theme.palette.text.disabled : "" }}>
          {ticker} Value:{" "}
          {getAssetQtyByPrice(ticker, Number(amount), priceFeed, assets).toFixed(walletAsset?.decimalPrecision)}
        </Typography>
      )}
    </>
  );
};
