import { useLocation, useNavigate, useParams, useRouteError } from "react-router-dom";
import React, { SetStateAction, useEffect, useState } from "react";
import { AxiosError } from "axios";
import QRCode from "react-qr-code";
import {
  ClickAwayListener,
  Alert,
  AlertTitle,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
  FormControlLabel,
  Stack,
  Box,
} from "@mui/material";

import {
  Button,
  Dialog,
  DialogTitle,
  FormLabel,
  Icons,
  Radio,
  RadioGroup,
  color,
  formatCryptoQuantity,
  formatDollarAmountUsd,
} from "@bakkt/bakkt-ui-components";
import { Asset, PriceInfo, Status, Wallet, WalletType } from "../../services/openAPI/client";
import {
  convertPricingInfoArrayIntoMap,
  isTokenAsset,
  checkWarmBalance,
  checkWarmWallet,
  getScanLink,
  getMinimunFeeAmount,
} from "../../utils/dataUtils.ts";
import { RootContextType, useRootContext } from "../../RootLayout";
import { isREL009PublicFeature } from "../../config/firebaseConfig.ts";
import { AddressTicker } from "../../utils/customTypes.ts";
import AssetDropDown from "../../components/assetDropDown/AssetDropDown.tsx";
import { CryptoTickerEnum } from "../../utils/CryptoIconsMap.ts";

export default function WalletDeposit() {
  const { orgDataCache, priceFeed, assets, networks } = useRootContext() as RootContextType;
  const wallets = [...orgDataCache.wallets, ...orgDataCache.omnibusWallets];
  const { state } = useLocation();
  const assetSymbol = state?.assetSymbol;
  const BTCAsset = assets.find((asset) => asset.ticker === CryptoTickerEnum.BTC);

  //For Token Assets
  const [isToken, setIsToken] = useState<boolean>(false);
  const [feeWallet, setFeeWallet] = useState<Wallet | null>(null);

  const { walletId } = useParams();
  const navigate = useNavigate();
  const error = useRouteError() as AxiosError;

  const getSelectedWallet = (walletId: any) => {
    return wallets.find((wallet) => wallet.walletId == walletId);
  };

  const [selectedWalletId, setSelectedWalletId] = useState<string | undefined>(walletId);
  const [selectedWallet, setSelectedWallet] = useState<Wallet>(getSelectedWallet(selectedWalletId as string) as Wallet);
  const [selectedAsset, setSelectedAsset] = useState<string>(
    selectedWallet?.assetSymbol || assetSymbol || BTCAsset?.symbol,
  );
  const [selectedWalletType, setSelectedWalletType] = useState<WalletType | undefined>();
  const [depositWallets, setDepositWallets] = useState<Wallet[]>();
  const [open, setOpen] = useState(true);
  const [showCopyAddressConfirm, setShowCopyAddressConfirm] = useState(false);

  const selectedAddressType =
    selectedWallet?.type === WalletType.Custody ? selectedWallet.address || "" : selectedWallet?.depositAddress || "";

  const addressTicker: AddressTicker = {
    assetTicker: selectedWallet?.assetTicker,
    address: selectedAddressType,
  };
  const handleTooltipClose = () => {
    setShowCopyAddressConfirm(false);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(selectedAddressType);
    setShowCopyAddressConfirm(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleChangeAsset = (_: React.SyntheticEvent, newAssetSymbol: Asset | null) => {
    if (newAssetSymbol) {
      setSelectedAsset(newAssetSymbol.symbol);
      setSelectedWalletId(undefined);
      setSelectedWalletType(undefined);
      //setIsERC20(isERC20Coin(event.target.value));
      updateTokenAssetChanges(newAssetSymbol.symbol, undefined);
    }
  };

  function updateTokenAssetChanges(updatedSymbol?: string, updatedWalletId?: number | undefined) {
    if (updatedSymbol) {
      setIsToken(isTokenAsset(updatedSymbol, assets));
    }
    const fromWallet: Wallet | null = wallets.find((wallet) => wallet.walletId === updatedWalletId) || null;
    const feeW = wallets.find((w) => fromWallet?.feeWalletId === w.walletId) || null;
    setFeeWallet(feeW);
  }

  const handleChangeWallet = (event: SelectChangeEvent) => {
    setSelectedWalletId(event.target.value);
    setSelectedWallet(getSelectedWallet(event.target.value) as SetStateAction<Wallet>);
    updateTokenAssetChanges(undefined, Number(event.target.value));
  };

  const SelectedWalletBalance = (wallet: any) => {
    const pricingInfoMap = convertPricingInfoArrayIntoMap(priceFeed, assets);
    const priceInfo = pricingInfoMap[wallet?.assetTicker];
    const balance = wallet?.quantity * (priceInfo.bidPrice || 0);
    return formatDollarAmountUsd(balance);
  };

  const handleWalletType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedWalletId(undefined);
    const walletType = event.target.value as WalletType;
    setSelectedWalletType(walletType);
    const filteredWallets = getDepositWallets(wallets, walletType);
    setDepositWallets(filteredWallets);
    if (walletType === WalletType.Trading) {
      const omnibusWallet = filteredWallets[0]?.walletId;
      /*
        checking walletId is true to not get console error when setting select walletID
      */
      if (omnibusWallet) {
        setSelectedWalletId(String(omnibusWallet) as SetStateAction<string | undefined>);
        const selectedOmnibusWallet = getSelectedWallet(omnibusWallet);
        setSelectedWallet(selectedOmnibusWallet as Wallet);
      }
    }
  };

  const getDepositWallets = (wallets: Wallet[], walletType: string) => {
    return wallets.filter((wallet: Wallet) => wallet.type === walletType && wallet.assetSymbol === selectedAsset);
  };

  useEffect(() => {
    if (selectedWallet) {
      //set Token Asset related values
      updateTokenAssetChanges(selectedWallet?.assetSymbol || "", selectedWallet.walletId);
    }
  }, []);

  const isWarmBalance = checkWarmBalance(wallets as Wallet[], priceFeed as PriceInfo[], assets);
  const disableOmnibus = orgDataCache.omnibusWallets.length === 0;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
      <DialogTitle title={"Deposit"}>
        Select the asset and wallet to make a deposit. Then scan this QR code or copy the link below to make a secure
        deposit.
      </DialogTitle>

      <DialogContent>
        {isToken && feeWallet && (
          <Alert severity="info" sx={{ mb: 3 }}>
            This asset is on {feeWallet.network}. {feeWallet.network} assets require a minimum balance of{" "}
            {getMinimunFeeAmount(feeWallet.assetTicker, assets)} {feeWallet.assetTicker} in {feeWallet.name} for GAS
            fees. {feeWallet.assetTicker} will be deducted from {feeWallet.assetTicker} wallet {feeWallet.name} for GAS
            fees.
          </Alert>
        )}
        <Grid container>
          <Grid xs={12}>
            {error && (
              <Alert severity="error" sx={{ pb: 4 }}>
                <AlertTitle>Error</AlertTitle>
                {error.message || "Something went wrong."}
              </Alert>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} data-testid="addWalletContainerGrid">
          <Grid xs={12} sx={{ mb: 2 }}>
            <FormControl variant="standard" sx={{ width: "100%" }} required>
              <AssetDropDown
                handleAssetChange={handleChangeAsset}
                assets={assets.filter((asset) => asset.status === Status.Active)}
                selectedAsset={selectedAsset}
              />
            </FormControl>
          </Grid>
          {isREL009PublicFeature && (
            <Grid xs={12}>
              <FormControl variant="standard" required>
                <FormLabel>Select omnibus or segregated wallet</FormLabel>
                <Typography variant="body2">
                  Omnibus wallets can be used for off-chain fee-free transfers, while segregated wallets are used for
                  on-chain transactions.
                </Typography>
                <RadioGroup onChange={handleWalletType} name="wallet-type">
                  <FormControlLabel
                    checked={selectedWalletType ? selectedWalletType === WalletType.Custody : false}
                    value={WalletType.Custody}
                    control={<Radio color="secondary" />}
                    label="Segregated"
                  />
                  <Stack direction={"row"} alignContent={"flex-end"} alignItems={"flex-end"} sx={{ p: 0, m: 0 }}>
                    <FormControlLabel
                      value={WalletType.Trading}
                      checked={selectedWalletType ? selectedWalletType === WalletType.Trading : false}
                      control={<Radio color="secondary" />}
                      label="Omnibus"
                      sx={{ mr: 0 }}
                      disabled={disableOmnibus}
                    />
                    {disableOmnibus && (
                      <Tooltip
                        title={
                          <Typography variant="body2">
                            Please activate omnibus in the dashboard in order to create omnibus wallets.
                          </Typography>
                        }
                        placement="top"
                      >
                        <Box>
                          <SvgIcon
                            component={Icons.InformationIcon}
                            sx={{ width: 22, height: 22, color: color.text.disabled }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {!isREL009PublicFeature && (
            <Grid xs={12} sx={{ mb: 2 }}>
              <FormControl variant="standard" sx={{ width: "100%" }} required>
                <InputLabel id="select-asset">Wallet</InputLabel>
                <Select value={selectedWalletId?.toString() || ""} label="Wallet" onChange={handleChangeWallet}>
                  {wallets
                    ?.filter(
                      (wallet) =>
                        wallet.assetSymbol === selectedAsset &&
                        checkWarmWallet(isWarmBalance, wallet.temperature as string) &&
                        wallet.type === WalletType.Custody,
                    )
                    .map((wallet) => (
                      <MenuItem key={wallet.walletId} value={wallet.walletId}>
                        <Grid container direction={"column"} justifyContent={"center"}>
                          <Grid xs={6}>
                            <Typography sx={{ fontWeight: 600 }}>{wallet.name}</Typography>
                          </Grid>
                          <Grid xs={6}>
                            <Typography variant={"body1"}>{`ID: ${wallet.walletId}`}</Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {isREL009PublicFeature && selectedWalletType && (
            <Grid xs={12} sx={{ mb: 2 }}>
              {selectedWalletType === WalletType.Custody ? (
                <FormControl variant="standard" sx={{ width: "100%" }} required>
                  <InputLabel id="select-wallet">Wallet</InputLabel>
                  <Select value={selectedWalletId?.toString() || ""} label="Wallet" onChange={handleChangeWallet}>
                    {depositWallets
                      ?.filter(
                        (wallet) =>
                          wallet.assetSymbol === selectedAsset &&
                          checkWarmWallet(isWarmBalance, wallet.temperature as string),
                      )
                      .map((wallet) => (
                        <MenuItem key={wallet.walletId} value={wallet.walletId}>
                          <Grid container direction={"column"} justifyContent={"center"}>
                            <Grid xs={6}>
                              <Typography sx={{ fontWeight: 600 }}>{wallet.name}</Typography>
                            </Grid>
                            <Grid xs={6}>
                              <Typography variant={"body1"}>{`ID: ${wallet.walletId}`}</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <>
                  <Typography variant="body2">Wallet*</Typography>
                  {selectedWalletId ? (
                    <Grid container direction={"column"} justifyContent={"center"}>
                      <Grid xs={6}>
                        <Typography sx={{ fontWeight: 600 }}>{selectedWallet?.name}</Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant={"body1"}>{`ID: ${selectedWallet?.address}`}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container direction={"column"} justifyContent={"center"}>
                      <Grid xs={6}>
                        <Typography sx={{ fontWeight: 600 }}>No omnibus wallet created for asset</Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
          {selectedWalletId && (
            <Grid container xs={12}>
              <Grid xs={4}>
                <QRCode size={192} value={selectedAddressType} viewBox={`0 0 192 192`} />
              </Grid>
              <Grid container direction={"column"} justifyContent={"space-evenly"} spacing={0.5}>
                <Grid>
                  <Typography sx={{ fontWeight: 600 }}>{selectedWallet?.name}</Typography>
                  <Typography variant={"body1"}>{`ID: ${
                    selectedWallet.type === WalletType.Custody ? selectedWallet.walletId : selectedWallet.address
                  }`}</Typography>
                </Grid>

                <Grid>
                  <Typography variant={"body1"}>{`Description: ${
                    selectedWallet.description ?? "No description provided"
                  }`}</Typography>
                  <Typography variant={"body1"}>{`Quantity: ${formatCryptoQuantity(
                    selectedWallet.quantity || 0,
                    selectedWallet.assetTicker,
                    "long",
                  )}`}</Typography>
                  <Typography variant={"body1"}>{`Balance: ${SelectedWalletBalance(selectedWallet)}`}</Typography>
                </Grid>

                <Grid container spacing={0} direction={"row"} alignItems={"flex-end"}>
                  <Grid sx={{ mr: 0.1 }}>
                    <Typography variant={"body1"}>{selectedAddressType}</Typography>
                  </Grid>
                  <Grid>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        onClose={handleTooltipClose}
                        open={showCopyAddressConfirm}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        placement="top"
                        title="Address Copied"
                      >
                        <IconButton sx={{ m: 0, p: 0 }} onClick={copyAddress} disableRipple disableFocusRipple>
                          <SvgIcon
                            component={Icons.CopyIcon}
                            inheritViewBox
                            sx={{ width: "15px", height: "15px", ml: 1, mr: 1.2 }}
                          />
                        </IconButton>
                      </Tooltip>
                    </ClickAwayListener>
                  </Grid>
                  <Grid>
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      href={getScanLink(
                        selectedWallet.assetSymbol || "",
                        selectedWallet.address || "",
                        assets,
                        networks,
                      )}
                      target="_blank"
                    >
                      <SvgIcon component={Icons.GoToIcon} inheritViewBox sx={{ width: "16px", height: "16px" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
